import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import notifyReducer from "react-redux-notify";
import CardsReducer from "./CardsReducer";
import BillingAccountReducer from "./BillingAccountReducer";
import ErrorReducer from "./ErrorReducer";
import WishlistReducer from "./WishlistReducer";
import VehicleReducer from "./VehicleReducer";
import BookingReducer from "./BookingReducer";
import NotificationReducer from "./NotificationReducer";
import WalletReducer from "./WalletReducer";
import TransactionReducer from "./TransactionReducer";
import KycDocumentReducer from "./KycDocumentReducer";
import PageReducer from "./PageReducer";

export default combineReducers({
  users: UserReducer,
  cards: CardsReducer,
  billingAccount: BillingAccountReducer,
  wishlists: WishlistReducer,
  vehicles: VehicleReducer,
  bookings: BookingReducer,
  errorDetails: ErrorReducer,
  notification: NotificationReducer,
  notifications: notifyReducer,
  wallet: WalletReducer,
  transaction: TransactionReducer,
  kycDocument: KycDocumentReducer,
  page: PageReducer,
});
