import React, { useState } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import Background from "./profile-cover.jpg";
import { deleteAccountStart } from "../../store/actions/UserAction";
import "./Account.css";

const DeleteAccount = (props) => {
  const [password, setPassword] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(deleteAccountStart({ password: password }));
  };
  return (
    <>
      <div
        className="delete-account-bg-sec"
        style={{
          background: `url(${Background})`,
        }}
      ></div>
      <section className="delete-account-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mx-auto">
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("delete_account")}</h3>
                </div>
                <div className="form-content">
                  <div className="contact-form-action">
                    <form autoComplete="new-password" onSubmit={handleSubmit}>
                      <h5 className="sub-title">{t("delete_account_note")}</h5>
                      <div className="row">
                        <div className="col-lg-12 responsive-column">
                          <div className="input-box">
                            <label className="label-text">
                              {t("enter_your_password")}
                            </label>
                            <div className="form-group">
                              <span className="la la-lock form-icon"></span>
                              <input
                                className="form-control"
                                type="password"
                                placeholder={t("password")}
                                required
                                value={password}
                                onChange={(event) =>
                                  setPassword(event.currentTarget.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="btn-box">
                            <button
                              className="theme-btn"
                              type="submit"
                              onSubmit={handleSubmit}
                              disabled={props.deleteAccount.buttonDisable}
                            >
                              {props.deleteAccount.loadingButtonContent !== null
                                ? props.deleteAccount.loadingButtonContent
                                : t("delete_account")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  deleteAccount: state.users.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(DeleteAccount);
