import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./NoDataFound.css";
const NoDataFound = (props) => {
  return (
    <>
      <section className="no-data-found-sec">
        <div className="container">
          <div className="no-data-found-img-sec">
            <img
              src={window.location.origin + `/assets/images/no-data-found.svg`}
              alt="user-image"
              className="no-data-found-img"
            />
          </div>
        </div>
      </section>
    </>
  );
};


export default NoDataFound;