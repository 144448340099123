import React, { useState, useEffect } from "react";
import { t } from "react-multi-lang";

const VehicleCardSec = (props) => {
  const vehicleInfo = props.vehicleInfo;
  return (
    <>
      <div className="card-item car-card card-item-list">
        <div className="card-img padding-top-50px">
          <a
            href={`/vehicle-details/` + vehicleInfo.pro_vehicle_unique_id}
            className="d-block"
          >
            <img src={vehicleInfo.picture} alt="car-img" className="h-100" />
          </a>
          <span className="badge">
            {vehicleInfo.total_bookings} {t("bookings")}
          </span>
          <div
            className="add-to-wishlist icon-element"
            data-toggle="tooltip"
            data-placement="top"
            title="Save for later"
          >
            <i className="la la-heart-o"></i>
          </div>
        </div>
        <div className="card-body">
          <p className="card-meta">{vehicleInfo.category.name}</p>
          <h3 className="card-title">
            <a href={`/vehicle-details/` + vehicleInfo.pro_vehicle_unique_id}>
              {vehicleInfo.name}
            </a>
          </h3>
          <div className="card-rating">
            <span className="badge text-white">
              {vehicleInfo.overall_rating}/5
            </span>
            <span className="review__text">{t("average")}</span>
            <span className="rating__text">
              ({vehicleInfo.total_reviews} {t("reviews")})
            </span>
          </div>
          <div className="card-attributes">
            <ul className="d-flex align-items-center">
              <li
                className="d-flex align-items-center"
                data-toggle="tooltip"
                data-placement="top"
                title={t("no_of_seats")}
              >
                <i className="la la-users"></i>
                <span>{vehicleInfo.no_of_seats}</span>
              </li>
              <li
                className="d-flex align-items-center"
                data-toggle="tooltip"
                data-placement="top"
                title={t("is_fuel_included")}
              >
                <i className="la la-gas-pump"></i>
                {vehicleInfo.is_fuel_included ? (
                  <span className="text-primary">{t("with_fuel")} </span>
                ) : (
                  <span className="text-muted"> {t("without_fuel")}</span>
                )}
              </li>
            </ul>
          </div>
          <div className="card-price d-flex align-items-center justify-content-between">
            <p>
              <span className="price__from">From</span>
              <span className="price__num">
                {vehicleInfo.price_per_day_formatted}
              </span>
              <span className="price__text">{t("per_day")}</span> |{" "}
              <span className="price__num">
                {vehicleInfo.price_per_month_formatted}
              </span>
              <span className="price__text">{t("per_month")}</span>
            </p>
            <a
              href={`/vehicle-details/` + vehicleInfo.pro_vehicle_unique_id}
              className="btn-text"
            >
              {t("see_details")}
              <i className="la la-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleCardSec;
