import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  CREATE_BOOKING_START,
  FETCH_BOOKING_HISTORY_START,
  FETCH_BOOKING_UPCOMING_START,
  FETCH_BOOKING_VIEW_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  createBookingFailure,
  createBookingSuccess,
  fetchBookingCancelledFailure,
  fetchBookingCancelledSuccess,
  fetchBookingHistoryFailure,
  fetchBookingHistorySuccess,
  fetchBookingUpcomingFailure,
  fetchBookingUpcomingSuccess,
  fetchBookingViewFailure,
  fetchBookingViewSuccess,
} from "../actions/BookingAction";

function* fetchBookingHistoryAPI() {
  try {
    const skipCount = yield select((state) => state.bookings.bookingList.skip);
    const response = yield api.postMethod("bookings_history", {
      skip: skipCount,
    });
    if (response.data.success) {
      yield put(fetchBookingHistorySuccess(response.data.data));
    } else {
      yield put(fetchBookingHistoryFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookingHistoryFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBookingUpcomingAPI() {
  try {
    const response = yield api.postMethod("bookings_history");

    if (response.data.success) {
      yield put(fetchBookingUpcomingSuccess(response.data.data));
    } else {
      yield put(fetchBookingUpcomingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookingUpcomingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBookingCancelledAPI() {
  try {
    const response = yield api.postMethod("cancelled_bookings");

    if (response.data.success) {
      yield put(fetchBookingCancelledSuccess(response.data.data));
    } else {
      yield put(fetchBookingCancelledFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookingCancelledFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchBookingViewAPI() {
  try {
    const inputData = yield select((state) => state.bookings.booking.inputData);

    const response = yield api.postMethod("bookings_view", inputData);

    if (response.data.success) {
      yield put(fetchBookingViewSuccess(response.data.data));
    } else {
      yield put(fetchBookingViewFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchBookingViewFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* createBookingAPI() {
  try {
    const inputData = yield select(
      (state) => state.bookings.bookingInputData.inputData
    );

    const response = yield api.postMethod("bookings_create", inputData);

    if (response.data.success) {
      yield put(createBookingSuccess(response.data.data));
      window.location.replace("/bookings");
    } else {
      yield put(createBookingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(createBookingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_BOOKING_HISTORY_START, fetchBookingHistoryAPI),
  ]);

  yield all([
    yield takeLatest(FETCH_BOOKING_UPCOMING_START, fetchBookingUpcomingAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_BOOKING_UPCOMING_START, fetchBookingCancelledAPI),
  ]);
  yield all([yield takeLatest(FETCH_BOOKING_VIEW_START, fetchBookingViewAPI)]);
  yield all([yield takeLatest(CREATE_BOOKING_START, createBookingAPI)]);
}
