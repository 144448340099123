import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import configuration from "react-global-configuration";
import SocialButton from "../Helper/SocialButton";
import { userLoginStart, userRegisterStart } from "../../store/actions/UserAction";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { gapi } from "gapi-script";

const LoginModal = (props) => {
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });

}, []);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const handleClose = () => setShowForgotPassword(false);

  const [loginInputData, setLoginInputData] = useState({});
  const handleLogin = (event) => {
    event.preventDefault();
    props.dispatch(userLoginStart(loginInputData));
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className="modal-popup">
        <Modal
          show={props.showLogin}
          onHide={props.handleLoginClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <div>
              <h5 className="modal-title title" id="exampleModalLongTitle2">
                {t("login")}
              </h5>
              <p className="font-size-14">{t("login_note")}</p>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.handleLoginClose}
            >
              <span aria-hidden="true" className="la la-close"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="contact-form-action">
              <form method="post">
                <div className="input-box">
                  <label className="label-text">{t("email")}</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t("email_placeholder")}
                      required
                      value={loginInputData.email}
                      name="email"
                      onChange={(event) =>
                        setLoginInputData({
                          ...loginInputData,
                          email: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("password")}</label>
                  <div className="form-group mb-2">
                    <span className="la la-lock form-icon"></span>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder={t("password_placeholder")}
                      required
                      value={loginInputData.password}
                      onChange={(event) =>
                        setLoginInputData({
                          ...loginInputData,
                          password: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <div className="custom-checkbox mb-0">
                      <input type="checkbox" id="rememberchb" />
                      <label for="rememberchb">Remember me</label>
                    </div> */}
                    <p className="forgot-password">
                      <Link 
                        to="#"
                        onClick={(event) => {
                            event.preventDefault();
                            setShowForgotPassword(true);
                            props.handleLoginClose();
                        }}
                      >
                        {t("forgot_password")}
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="btn-box pt-3 pb-4">
                  <button
                    type="submit"
                    className="theme-btn w-100"
                    onClick={handleLogin}
                    disabled={props.login.buttonDisable}
                  >
                    {props.login.loadingButtonContent !== null
                      ? props.login.loadingButtonContent
                      : t("login_submit")}{" "}
                  </button>
                </div>
                <div className="action-box text-center">
                  <p className="font-size-14">{t("social_login_heading")}</p>
                  <ul className="social-profile py-3">
                    <li>
                      {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                        <SocialButton
                          provider="google"
                          key={"google"}
                          appId={configuration.get(
                            "configData.GOOGLE_CLIENT_ID"
                          )}
                          onLoginSuccess={handleGoogleLogin}
                          onLoginFailure={handleSocialLoginFailure}
                          className="btn bg-5 text-white"
                          id="google-connect"
                        >
                          <span>
                            <i className="lab la-google"></i>
                          </span>
                        </SocialButton>
                      ) : (
                        ""
                      )}

                    </li>
                    <li>
                      {configuration.get("configData.FB_CLIENT_ID") ? (
                        <SocialButton
                          provider="facebook"
                          appId={configuration.get("configData.FB_CLIENT_ID")}
                          onLoginSuccess={handleFacebookLogin}
                          onLoginFailure={handleSocialLoginFailure}
                          className="btn bg-6 text-white"
                          id="facebook-connect"
                        >
                          <span>
                            <i className="lab la-facebook-f"></i>
                          </span>
                        </SocialButton>
                      ) : (
                        ""
                      )}</li>

                  </ul>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <ForgotPasswordModal showForgotPassword={showForgotPassword} handleClose={handleClose} />

      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  // signup: state.users.registerInputData,
  // forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginModal);
