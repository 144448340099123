import {
  SEARCH_VEHICLES_START,
  SEARCH_VEHICLES_SUCCESS,
  SEARCH_VEHICLES_FAILURE,
  TRENDING_VEHICLES_START,
  TRENDING_VEHICLES_SUCCESS,
  TRENDING_VEHICLES_FAILURE,
  TOP_SERVICE_LOCATIONS_START,
  TOP_SERVICE_LOCATIONS_SUCCESS,
  TOP_SERVICE_LOCATIONS_FAILURE,
  FETCH_SINGLE_VEHICLE_START,
  FETCH_SINGLE_VEHICLE_SUCCESS,
  FETCH_SINGLE_VEHICLE_FAILURE,
  FETCH_VEHICLE_REVIEWS_START,
  FETCH_VEHICLE_REVIEWS_SUCCESS,
  FETCH_VEHICLE_REVIEWS_FAILURE,
  FETCH_FAQS_START,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE,
} from "./ActionConstant";

// search vehicles actions.

export function searchVehiclesStart(data) {
  return {
    type: SEARCH_VEHICLES_START,
    data,
  };
}

export function searchVehiclesSuccess(data) {
  return {
    type: SEARCH_VEHICLES_SUCCESS,
    data,
  };
}

export function searchVehiclesFailure(error) {
  return {
    type: SEARCH_VEHICLES_FAILURE,
    error,
  };
}

// Top vehicles actions.

export function trendingVehiclesStart(data) {
  return {
    type: TRENDING_VEHICLES_START,
    data,
  };
}

export function trendingVehiclesSuccess(data) {
  return {
    type: TRENDING_VEHICLES_SUCCESS,
    data,
  };
}

export function trendingVehiclesFailure(error) {
  return {
    type: TRENDING_VEHICLES_FAILURE,
    error,
  };
}

// TOP service locations actions.

export function topServiceLocationsStart(data) {
  return {
    type: TOP_SERVICE_LOCATIONS_START,
    data,
  };
}

export function topServiceLocationsSuccess(data) {
  return {
    type: TOP_SERVICE_LOCATIONS_SUCCESS,
    data,
  };
}

export function topServiceLocationsFailure(error) {
  return {
    type: TOP_SERVICE_LOCATIONS_FAILURE,
    error,
  };
}
// vehicle single.

export function fetchSingleVehicleStart(data) {
  return {
    type: FETCH_SINGLE_VEHICLE_START,
    data,
  };
}

export function fetchSingleVehicleSuccess(data) {
  return {
    type: FETCH_SINGLE_VEHICLE_SUCCESS,
    data,
  };
}

export function fetchSingleVehicleFailure(error) {
  return {
    type: FETCH_SINGLE_VEHICLE_FAILURE,
    error,
  };
}

// vehicle Reviews.

export function fetchVehicleReviewsStart(data) {
  return {
    type: FETCH_VEHICLE_REVIEWS_START,
    data,
  };
}

export function fetchVehicleReviewsSuccess(data) {
  return {
    type: FETCH_VEHICLE_REVIEWS_SUCCESS,
    data,
  };
}

export function fetchVehicleReviewsFailure(error) {
  return {
    type: FETCH_VEHICLE_REVIEWS_FAILURE,
    error,
  };
}

export function fetchFaqsStart(data) {
  return {
    type: FETCH_FAQS_START,
    data,
  };
}

export function fetchFaqsSuccess(data) {
  return {
    type: FETCH_FAQS_SUCCESS,
    data,
  };
}

export function fetchFaqsFailure(error) {
  return {
    type: FETCH_FAQS_FAILURE,
    error,
  };
}