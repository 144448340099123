import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { fetchBookingViewStart } from "../../store/actions/BookingAction";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";

const BookingView = (props) => {
  const [mapPosition, setMapPosition] = useState('');

  useEffect(() => {
    if (props.booking.loading)
      props.dispatch(
        fetchBookingViewStart({
          booking_unique_id: props.match.params.booking_unique_id,
        })
      );
  }, []);

  const bookingDetails = props.booking;
  return (
    <>
      {bookingDetails.loading ? (
        ""
      ) : (
        <>
          <section
            className="breadcrumb-area bread-bg-8 py-0"
            style={{
              backgroundImage:
                "url('" + bookingDetails.data.pro_vehicle.picture + "')",
            }}
          >
            <div className="breadcrumb-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="breadcrumb-btn">
                      <div className="btn-box">
                        {/* <a
                          className="theme-btn"
                          data-fancybox="video"
                          data-src="https://www.youtube.com/watch?v=IPm6RPmI5_A"
                          data-speed="700"
                        >
                          <i className="la la-video-camera mr-2"></i>Video
                        </a> */}
                        <a
                          className="theme-btn"
                          data-src={
                            window.location.origin + "/images/car-img.jpg"
                          }
                          data-fancybox="gallery"
                          data-caption="Showing image - 01"
                          data-speed="700"
                          style={{ display: "none" }}
                        >
                          <i className="la la-photo mr-2"></i>More Photos
                        </a>
                      </div>
                      <a
                        className="d-none"
                        data-fancybox="gallery"
                        data-src="images/car-img2.jpg"
                        data-caption="Showing image - 02"
                        data-speed="700"
                      ></a>
                      <a
                        className="d-none"
                        data-fancybox="gallery"
                        data-src="images/car-img3.jpg"
                        data-caption="Showing image - 03"
                        data-speed="700"
                      ></a>
                      <a
                        className="d-none"
                        data-fancybox="gallery"
                        data-src="images/car-img4.jpg"
                        data-caption="Showing image - 04"
                        data-speed="700"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="funfact-area booking-view-sec">
            <div className="container">
              <div className="row margin-top-60px mb-0">
                <div className="col-lg-8">
                  <div className="single-content-wrap form-box">
                    <div id="description" className="page-scroll form-content">
                      <div className="single-content-item pb-4">
                        <h3 className="title font-size-26">
                          {bookingDetails.data.pro_vehicle.name}
                        </h3>
                        <div className="d-flex align-items-center pt-2">
                          <p className="mr-2">{bookingDetails.data.location}</p>
                          <p>
                            <span className="badge badge-warning text-white font-size-16 mr-2">
                              {bookingDetails.data.pro_vehicle.overall_rating}
                            </span>
                            <span>
                              ({bookingDetails.data.pro_vehicle.total_ratings}{" "}
                              {t("reviews")})
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="section-block"></div>
                      <div className="single-content-item py-4">
                        <div className="row">
                          <div className="col-lg-6 responsive-column">
                            <div className="single-feature-titles mb-3">
                              <h3 className="title font-size-16">
                                {t("pickup_location")}
                              </h3>
                              <span className="font-size-13">
                                {bookingDetails.data.pro_vehicle.location}
                              </span>
                            </div>
                            <div className="section-block"></div>
                            <div className="single-feature-titles my-3">
                              <h3 className="title font-size-15 font-weight-medium">
                                {t("pickup_date")}
                              </h3>
                              <span className="font-size-13">
                                {bookingDetails.data.booking.start_date_formatted}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="single-feature-titles mb-3">
                              <h3 className="title font-size-16">
                                {t("drop_location")}
                              </h3>
                              <span className="font-size-13">
                                {bookingDetails.data.pro_vehicle.location}
                              </span>
                            </div>
                            <div className="section-block"></div>
                            <div className="single-feature-titles my-3">
                              <h3 className="title font-size-15 font-weight-medium">
                                {t("drop_date")}
                              </h3>
                              <span className="font-size-13">
                                {bookingDetails.data.booking.end_date_formatted}
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="section-block margin-bottom-35px"></div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-car"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("car_name")}
                                </h3>
                                <span className="font-size-13">
                                  {bookingDetails.data.pro_vehicle.name}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-car"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("car_model")}
                                </h3>
                                <span className="font-size-13">{bookingDetails.data.pro_vehicle.vehicle_model}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-car"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("engine_type")}
                                </h3>
                                <span className="font-size-13 text-capitalize">
                                  {bookingDetails.data.pro_vehicle.engine_type}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-users"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("no_of_airbags")}
                                </h3>
                                <span className="font-size-13">{bookingDetails.data.pro_vehicle.no_of_airbags}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-briefcase"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("fuel_unit")}
                                </h3>
                                <span className="font-size-13  text-capitalize">{bookingDetails.data.pro_vehicle.fuel_unit}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 responsive-column">
                            <div className="single-tour-feature d-flex align-items-center mb-3">
                              <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                <i className="la la-gear"></i>
                              </div>
                              <div className="single-feature-titles">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("fuel_tank_capacity")}
                                </h3>
                                <span className="font-size-13">{bookingDetails.data.pro_vehicle.fuel_tank_capacity}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="section-block"></div>
                      <div className="single-content-item padding-top-20px">
                        <h3 className="title font-size-20">
                          {t("car_rental_information")}
                        </h3>
                        <p className="py-3"
                          dangerouslySetInnerHTML={{
                            __html: bookingDetails.data.pro_vehicle.description,
                          }}>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="single-content-wrap form-box">
                    <Map
                      className="new-map"
                      google={props.google}
                      visible={true}
                      height="400px"
                      center={{
                        lat: bookingDetails.data.pro_vehicle.latitude,
                        lng: bookingDetails.data.pro_vehicle.longitude
                      }}
                    >
                      <Marker position={mapPosition} />
                    </Map>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-box booking-detail-form">
                    <div className="form-title-wrap">
                      <h3 className="title">{t("booking_details")}</h3>
                    </div>
                    <div className="form-content">
                      <div className="card-item shadow-none radius-none mb-0">
                        <div className="booking-view-user-details">
                          <div className="user-img-sec">
                            <img
                              src={
                                window.location.origin +
                                "/assets/images/placeholder-profile.jpg"
                              }
                              alt=""
                              className="user-profile-img"
                            />
                          </div>
                          <div className="booking-details">
                            <h3 className="title">{bookingDetails.data.user.name}</h3>
                            <p className="card-meta">{bookingDetails.data.user.email}</p>
                          </div>
                        </div>
                        <div className="card-body p-0">
                          <div className="section-block"></div>
                          <ul className="list-items list-items-2 list--items-2 py-2">
                            <li className="font-size-15">
                              <span className="w-auto d-block mb-n1">
                                <i className="la la-calendar mr-1 font-size-17"></i>
                                {t("date")}
                              </span>
                              {bookingDetails.data.booking_date_formatted}
                            </li>
                            <li className="font-size-15">
                              <span className="w-auto d-block mb-n1">
                                <i className="la la-clock-o mr-1 font-size-17"></i>
                                {t("time")}
                              </span>
                              {bookingDetails.data.booking_time_formatted}
                            </li>
                            <li className="font-size-15">
                              <span className="w-auto d-block mb-n1">
                                <i className="la la-map-marker mr-1 font-size-17"></i>
                                {t("location")}
                              </span>
                              {bookingDetails.data.pro_vehicle.location}
                            </li>
                          </ul>
                          <h3 className="card-title pb-3">{t("order_details")}</h3>
                          <div className="section-block"></div>
                          <ul className="list-items list-items-2 py-3">
                            <li>
                              <span>{t("mileage_included")} :</span>{bookingDetails.data.pro_vehicle.mileage} Miles
                            </li>
                            <li>
                              <span>{t("per_day_price")} :</span> {bookingDetails.data.pro_vehicle.price_per_day_formatted}
                            </li>
                            <li>
                              <span>{t("no_of_seats")} :</span>{bookingDetails.data.pro_vehicle.no_of_seats}
                            </li>
                          </ul>
                          <div className="section-block"></div>
                          <ul className="list-items list-items-2 pt-3">
                            <li>
                              <span>{t("sub_total")} :</span>{bookingDetails.data.booking.booking_payments.sub_total_formatted}
                            </li>
                            <li>
                              <span>{t("taxes_and_fees")} :</span>{bookingDetails.data.booking.booking_payments.tax_price_formatted}
                            </li>
                            <li>
                              <span>{t("security_deposite")} :</span>{bookingDetails.data.booking.booking_payments.security_deposit_formatted}
                            </li>
                            <li>
                              <span>{t("total_price")} :</span>{bookingDetails.data.booking.booking_payments.paid_amount_formatted}
                            </li>
                          </ul>
                          <div className="pt-2">
                            <button
                              className="theme-btn btn-block theme-btn-small border-0 text-white bg-7 mr-3"
                              type="submit"
                            >
                              <i className="la la-times mr-1"></i>{t("cancel")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  booking: state.bookings.booking,
});
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(mapStateToPros, mapDispatchToProps)(BookingView);

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key
})(connector);
