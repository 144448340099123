import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import moment from "moment";
import TopServiceLocations from "./TopServiceLocations";
import HomeFaq from "./HomeFaq";
import DropDownTimePicker from "../Helper/DropDownTimePicker";
import DatePicker from "react-datepicker";
import "date-fns";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import "./Home.css";
import { searchVehiclesStart } from "../../store/actions/VehicleAction";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../Constant/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const HomeIndex = (props) => {
  useEffect(() => {}, []);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [inputData, setInputData] = useState({});

  let autocomplete;

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("search_input", JSON.stringify(inputData));
    props.dispatch(searchVehiclesStart(inputData));
    props.history.push("/vehicles");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData({
      ...inputData,
      latitude: 12.0,
      longitude: 72.0,
    });
    setInputData((inputData) => ({ ...inputData, [name]: value }));
  };

  const changeStartDate = (date) => {
    setStartDate(date);
    setInputData({
      ...inputData,
      start_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
    setEndDate(addDays(new Date(), 1));
  };
  const changeEndDate = (date) => {
    setEndDate(date);
    setInputData({
      ...inputData,
      end_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const renderAutoComplete = () => {
    console.log("asdfsf");
    const { google } = props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place.geometry);
      console.log("asdf", autocomplete.getPlace());
      if (!place.geometry) {
        console.log("sdfsd efe");
        return;
      }
      // this.setState({ position: place.geometry.location });

      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      console.log("data", inputData);
      console.log("lat", place.geometry.location.lat());

      setInputData({
        ...inputData,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        full_address: full_address,
      });
    });
  };

  return (
    <>
      <section className="hero-wrapper hero-wrapper4">
        <div className="hero-box hero-bg-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="hero-content mt-0">
                  <div className="section-heading">
                    <h2 className="sec__title">
                      Find the Best Car that <br />
                      Fits Your Needs
                    </h2>
                    <p className="sec__desc pt-3 font-size-18">
                      {t("site_about_text", {
                        sitename: configuration.get("configData.site_name"),
                      })}
                    </p>
                  </div>
                </div>
                <div className="hero-list-box margin-top-40px">
                  <ul className="list-items">
                    <li
                      className="d-flex align-items-center"
                      key="free-cancellations"
                    >
                      <i className="fa fa-wallet"></i>
                      <span>Free Cancellations</span>
                    </li>
                    <li
                      className="d-flex align-items-center"
                      key="24-7-support"
                    >
                      <i className="fa fa-wallet"></i>
                      <span>24/7 Customer Support</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="search-fields-container search-fields-container-shape">
                  <div className="search-fields-container-inner">
                    <h3 className="title pb-3">{t("home_search_title")}</h3>
                    <div className="section-block"></div>
                    <div className="contact-form-action pt-3">
                      <form
                        onSubmit={handleSubmit}
                        className="row"
                        autocomplete="off"
                      >
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">
                              {t("pickup_location")}
                            </label>
                            <div className="form-group">
                              <span className="la la-map-marker form-icon"></span>
                              <input
                                className="form-control"
                                type="text"
                                autocomplete="chrome-off"
                                placeholder={t("pickup_location_placeholder")}
                                name="address"
                                onFocus={renderAutoComplete}
                                ref={(ref) => (autocomplete = ref)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="input-box">
                            <label className="label-text">
                              {t("pickup_date")}
                            </label>
                            <div className="form-group">
                              <span className="la la-calendar form-icon"></span>
                              <DatePicker
                                selected={startDate}
                                name="start_date"
                                className="form-control"
                                minDate={new Date()}
                                excludeDates={[
                                  new Date(),
                                  subDays(new Date(), 1),
                                ]}
                                onChange={(date) => changeStartDate(date)}
                                // onChange={(date) => setStartDate(date)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 pl-0">
                          <div className="input-box select-custom">
                            <label className="label-text">{t("time")}</label>
                            {/* <div className="form-group"> */}
                            <div className="select-contain w-auto">
                              <DropDownTimePicker
                                name="start_time"
                                value={moment()
                                  .add(1, "hours")
                                  .format("HH:00:00")}
                                currentTime={moment().format("HH:00:00")}
                                handleChange={handleChange}
                              ></DropDownTimePicker>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="input-box">
                            <label className="label-text">
                              {t("drop_date")}
                            </label>
                            <div className="form-group">
                              <span className="la la-calendar form-icon"></span>
                              <DatePicker
                                selected={endDate}
                                name="end_date"
                                className="form-control"
                                // onChange={(date) => setEndDate(date)}
                                onChange={(date) => changeEndDate(date)}
                                minDate={startDate}
                                excludeDates={[
                                  new Date(),
                                  subDays(new Date(), 1),
                                ]}
                                currentTime={moment().format("HH:00:00")}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 pl-0">
                          <div className="input-box select-custom">
                            <label className="label-text">{t("time")}</label>
                            <div className="form-group">
                              <div className="select-contain select-contain-shadow w-auto">
                                <DropDownTimePicker
                                  name="end_time"
                                  value={moment().format("00:00:00")}
                                  currentTime={moment().format("00:00:00")}
                                  handleChange={handleChange}
                                ></DropDownTimePicker>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="btn-box pt-3">
                      <Link
                        className="theme-btn"
                        to={{
                          pathname: `/vehicles`,
                          // This is the trick! This link sets
                          // the `background` in location state.
                          state: { inputData },
                        }}
                      >
                        Search Now
                      </Link>
                      {/* <button onClick={handleSubmit} className="theme-btn">
                        Search Now
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="hero-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <polygon points="100 10 100 0 0 10"></polygon>
          </svg>
        </div>
      </section>
      {/* Section 2 start */}
      <section className="info-area info-bg padding-top-140px padding-bottom-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">Why Book with us?</h2>
              </div>
            </div>
          </div>
          <div className="row padding-top-60px">
            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb text-color-2">
                  <i className="la la-tags"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">Best Price Guarantee</h4>
                  <p className="info__desc">
                    {t("best_price_guarantee_text", {
                      sitename: configuration.get("configData.site_name"),
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb-2 text-color-3">
                  <i className="la la-money"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">No Booking Fees</h4>
                  <p className="info__desc">{t("pay_for_your_ride_text")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb-3 text-color-4">
                  <i className="la la-check"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">Flexible Payment Plans</h4>
                  <p className="info__desc">{t("pay_as_you_like")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section 2 end */}
      {/* Section 3 start */}
      <TopServiceLocations />
      {/* Section 3 end */}
      {/* Section 4 start */}
      <section className="info-area padding-bottom-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">How does it work?</h2>
              </div>
            </div>
          </div>

          <div className="row padding-top-50px">
            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-3 d-flex">
                <div className="info-icon flex-shrink-0">
                  <i className="la la-search-plus"></i>
                </div>

                <div className="info-content">
                  <h4 className="info__title">Find The Car</h4>
                  <p className="info__desc">
                    {t("find_the_car_text", {
                      sitename: configuration.get("configData.site_name"),
                    })}
                  </p>
                  <span className="info__num">1</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-3 d-flex">
                <div className="info-icon flex-shrink-0">
                  <i className="la la-mouse-pointer"></i>
                </div>

                <div className="info-content">
                  <h4 className="info__title">Book It</h4>
                  <p className="info__desc">{t("booking_made_easy_text")}</p>
                  <span className="info__num">2</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 responsive-column">
              <div className="icon-box icon-layout-3 d-flex">
                <div className="info-icon flex-shrink-0">
                  <i className="la la-check"></i>
                </div>

                <div className="info-content">
                  <h4 className="info__title">Grab And Go</h4>
                  <p className="info__desc">
                    {t("choose_your_pickup_location")}
                  </p>
                  <span className="info__num">3</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section 4 end */}
      {/* Section 5 start */}
      <HomeFaq />
      {/* Section 5 end */}
      {/* Section 6 start */}
      <section className="cta-area subscriber-area section-bg-2 padding-top-60px padding-bottom-60px">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-heading">
                <p className="sec__desc text-white-50 pb-1">
                  Get Updates & More
                </p>
                <h2 className="sec__title font-size-30 text-white">
                  {t("regular_updates_about_text")}
                </h2>
              </div>
            </div>
            {/* <div className="col-lg-5">
              <div className="subscriber-box">
                <div className="contact-form-action">
                  <form action="#">
                    <div className="input-box">
                      <label className="label-text text-white">
                        Enter email address
                      </label>
                      <div className="form-group mb-0">
                        <span className="la la-envelope form-icon"></span>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Email address"
                        />
                        <button
                          className="theme-btn theme-btn-small submit-btn"
                          type="submit"
                        >
                          Subscribe
                        </button>
                        <span className="font-size-14 pt-1 text-white-50">
                          <i className="la la-lock mr-1"></i>Don't worry your
                          information is safe with us.
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* Section 6 end */}
    </>
  );
};

const mapStateToPros = (state) => ({
  vehiclesData: state.vehicles.vehicles,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connect(mapStateToPros, mapDispatchToProps)(HomeIndex));
