import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./Wallet.css";
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";
import { fetchAllTransactionStart } from "../../../store/actions/TransactionAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import useInfiniteScroll from "../../Helper/useInfiniteScroll";


const WalletHistory = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.dispatch(
        fetchAllTransactionStart({
          skip: props.transaction.skip ? props.transaction.skip : 0,
        })
      );
    }, 2000);
  }, []);

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchWalletHistoryData);

  const [noMoreData, setNoMoreData] = useState(false);

  function fetchWalletHistoryData() {
    setTimeout(() => {
      if (props.transaction.data.history.length !== 0) {
        props.dispatch(
          fetchAllTransactionStart({ skip: props.transaction.skip })
        );
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  }
  return (
    <>
      {props.transaction.loading ? (
        ""
      ) : (
        <div className="dashboard-main-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-box no-border">
                  <div className="form-title-wrap">
                    <h3 className="title">{t("history")}</h3>
                  </div>
                  <div className="form-content">
                    {props.transaction.data.history.length > 0 ? (
                      <div className="table-form table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col text-uppercase">{t("s_no")}</th>
                              <th scope="col text-uppercase">
                                {t("payment_id")}
                              </th>
                              <th scope="col text-uppercase">
                                {t("payment_mode")}
                              </th>
                              <th scope="col text-uppercase">{t("total")}</th>
                              <th scope="col text-uppercase">{t("paid_at")}</th>
                              <th scope="col text-uppercase">{t("message")}</th>
                              <th scope="col text-uppercase">{t("status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.transaction.data.history.map(
                              (payment, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{payment.payment_id}</td>
                                  <td>{payment.payment_mode}</td>
                                  <td>{payment.paid_amount_formatted}</td>
                                  <td className="text-color-10">
                                    {payment.paid_date}
                                  </td>
                                  <td>{payment.message}</td>
                                  <td>
                                    <span className="text-color font-size-18">
                                      <i className="la la-check-circle"></i>
                                    </span>
                                    {payment.status_formatted}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <div className="btn-box text-right bottom-load-more">
                          <button type="button" className="theme-btn">
                            <i className="la la-refresh mr-1"></i>Load More
                          </button>{" "}
                        </div>
                      </div>
                    ) : (
                      <NoDataFound></NoDataFound>
                    )}

                    {noMoreData !== true ? (
                      <>{isFetching && "Fetching more list items..."}</>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  transaction: state.transaction.allTransaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletHistory);
