export const FETCH_USER_DETAILS_START = "FETCH_USER_DETAILS_START";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const GET_LOGIN_DETAILS = "GET_LOGIN_DETAILS";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_REGISTER_DETAILS = "GET_REGISTER_DETAILS";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_FORGOT_PASSWORD_DETAILS = "GET_FORGOT_PASSWORD_DETAILS";

export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";

export const UPDATE_USER_DETAILS_START = "UPDATE_USER_DETAILS_START";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const EDIT_CHANGE_PASSWORD = "EDIT_CHANGE_PASSWORD";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const FETCH_PAYMENTS_START = "FETCH_PAYMENTS_START";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";

// Cards constants

export const FETCH_CARDS_START = "FETCH_CARDS_START";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_FAILURE = "FETCH_CARDS_FAILURE";

export const DELETE_CARD_START = "DELETE_CARD_START";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const SELECT_DEFAULT_CARD_START = "SELECT_DEFAULT_CARD_START";
export const SELECT_DEFAULT_CARD_SUCCESS = "SELECT_DEFAULT_CARD_SUCCESS";
export const SELECT_DEFAULT_CARD_FAILURE = "SELECT_DEFAULT_CARD_FAILURE";

// Bank account
export const ADD_BILLING_ACCOUNT_START = "ADD_BILLING_ACCOUNT_START";
export const ADD_BILLING_ACCOUNT_SUCCESS = "ADD_BILLING_ACCOUNT_SUCCESS";
export const ADD_BILLING_ACCOUNT_FAILURE = "ADD_BILLING_ACCOUNT_FAILURE";

export const ADD_BILLING_ACCOUNT_DATA = "ADD_BILLING_ACCOUNT_DATA";

export const GET_BILLING_ACCOUNT_START = "GET_BILLING_ACCOUNT_START";
export const GET_BILLING_ACCOUNT_SUCCESS = "GET_BILLING_ACCOUNT_SUCCESS";
export const GET_BILLING_ACCOUNT_FAILURE = "GET_BILLING_ACCOUNT_FAILURE";

export const DELETE_BILLING_ACCOUNT_START = "DELETE_BILLING_ACCOUNT_START";
export const DELETE_BILLING_ACCOUNT_SUCCESS = "DELETE_BILLING_ACCOUNT_SUCCESS";
export const DELETE_BILLING_ACCOUNT_FAILURE = "DELETE_BILLING_ACCOUNT_FAILURE";

export const FETCH_SINGLE_BILLING_ACCOUNT_START =
  "FETCH_SINGLE_BILLING_ACCOUNT_START";
export const FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS =
  "FETCH_SINGLE_BILLING_ACCOUNT_SUCCESS";
export const FETCH_SINGLE_BILLING_ACCOUNT_FAILURE =
  "FETCH_SINGLE_BILLING_ACCOUNT_FAILURE";

export const MAKE_DEFAULT_BILLING_ACCOUNT_START =
  "MAKE_DEFAULT_BILLING_ACCOUNT_START";
export const MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS =
  "MAKE_DEFAULT_BILLING_ACCOUNT_SUCCESS";
export const MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE =
  "MAKE_DEFAULT_BILLING_ACCOUNT_FAILURE";

// Wallet constants
export const FETCH_WALLET_DETAILS_START = "FETCH_WALLET_DETAILS_START";
export const FETCH_WALLET_DETAILS_SUCCESS = "FETCH_WALLET_DETAILS_SUCCESS";
export const FETCH_WALLET_DETAILS_FAILURE = "FETCH_WALLET_DETAILS_FAILURE";

export const ADD_MONEY_VIA_CARD_START = "ADD_MONEY_VIA_CARD_START";
export const ADD_MONEY_VIA_CARD_SUCCESS = "ADD_MONEY_VIA_CARD_SUCCESS";
export const ADD_MONEY_VIA_CARD_FAILURE = "ADD_MONEY_VIA_CARD_FAILURE";

export const ADD_MONEY_VIA_BANK_START = "ADD_MONEY_VIA_BANK_START";
export const ADD_MONEY_VIA_BANK_SUCCESS = "ADD_MONEY_VIA_BANK_SUCCESS";
export const ADD_MONEY_VIA_BANK_FAILURE = "ADD_MONEY_VIA_BANK_FAILURE";

// Withdraw constants

export const SEND_WITHDRAW_REQUEST_START = "SEND_WITHDRAW_REQUEST_START";
export const SEND_WITHDRAW_REQUEST_SUCCESS = "SEND_WITHDRAW_REQUEST_SUCCESS";
export const SEND_WITHDRAW_REQUEST_FAILURE = "SEND_WITHDRAW_REQUEST_FAILURE";

export const FETCH_WITHDRAWALS_START = "FETCH_WITHDRAWALS_START";
export const FETCH_WITHDRAWALS_SUCCESS = "FETCH_WITHDRAWALS_SUCCESS";
export const FETCH_WITHDRAWALS_FAILURE = "FETCH_WITHDRAWALS_FAILURE";

export const CANCEL_WITHDRAW_REQUEST_START = "CANCEL_WITHDRAW_REQUEST_START";
export const CANCEL_WITHDRAW_REQUEST_SUCCESS =
  "CANCEL_WITHDRAW_REQUEST_SUCCESS";
export const CANCEL_WITHDRAW_REQUEST_FAILURE =
  "CANCEL_WITHDRAW_REQUEST_FAILURE";

export const SEARCH_WITHDRAWALS_START = "SEARCH_WITHDRAWALS_START";
export const SEARCH_WITHDRAWALS_SUCCESS = "SEARCH_WITHDRAWALS_SUCCESS";
export const SEARCH_WITHDRAWALS_FAILURE = "SEARCH_WITHDRAWALS_FAILURE";

export const FETCH_SINGLE_WITHDRAWALS_START = "FETCH_SINGLE_WITHDRAWALS_START";
export const FETCH_SINGLE_WITHDRAWALS_SUCCESS =
  "FETCH_SINGLE_WITHDRAWALS_SUCCESS";
export const FETCH_SINGLE_WITHDRAWALS_FAILURE =
  "FETCH_SINGLE_WITHDRAWALS_FAILURE";

// Registeration verify.
export const REGISTER_VERIFY_START = "REGISTER_VERIFY_START";
export const REGISTER_VERIFY_SUCCESS = "REGISTER_VERIFY_SUCCESS";
export const REGISTER_VERIFY_FAILURE = "REGISTER_VERIFY_FAILURE";

export const REGISTER_VERIFY_RESEND_START = "REGISTER_VERIFY_RESEND_START";
export const REGISTER_VERIFY_RESEND_SUCCESS = "REGISTER_VERIFY_RESEND_SUCCESS";
export const REGISTER_VERIFY_RESEND_FAILURE = "REGISTER_VERIFY_RESEND_FAILURE";

// Pages
export const FETCH_SINGLE_PAGE_START = "FETCH_SINGLE_PAGE_START";
export const FETCH_SINGLE_PAGE_SUCCESS = "FETCH_SINGLE_PAGE_SUCCESS";
export const FETCH_SINGLE_PAGE_FAILURE = "FETCH_SINGLE_PAGE_FAILURE";

// Notification on or off
export const NOTIFICATION_STATUS_UPDATE_START =
  "NOTIFICATION_STATUS_UPDATE_START";
export const NOTIFICATION_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_STATUS_UPDATE_FAILURE =
  "NOTIFICATION_STATUS_UPDATE_FAILURE";

// Error Handling for logout

export const ERROR_LOGOUT_CHECK = "ERROR_LOGOUT_CHECK";

// Notification constant
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

// Wishlists constant
export const GET_WISHLIST_START = "GET_WISHLIST_START";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURE";

export const ADD_WISHLIST_START = "ADD_WISHLIST_START";
export const ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS";
export const ADD_WISHLIST_FAILURE = "ADD_WISHLIST_FAILURE";

export const CLEAR_WISHLIST_START = "CLEAR_WISHLIST_START";
export const CLEAR_WISHLIST_SUCCESS = "CLEAR_WISHLIST_SUCCESS";
export const CLEAR_WISHLIST_FAILURE = "CLEAR_WISHLIST_FAILURE";

// Documents constant
export const FETCH_KYC_DOCUMENTS_START = "FETCH_KYC_DOCUMENTS_START";
export const FETCH_KYC_DOCUMENTS_SUCCESS = "FETCH_KYC_DOCUMENTS_SUCCESS";
export const FETCH_KYC_DOCUMENTS_FAILURE = "FETCH_KYC_DOCUMENTS_FAILURE";

export const SAVE_KYC_DOCUMENT_START = "SAVE_KYC_DOCUMENT_START";
export const SAVE_KYC_DOCUMENT_SUCCESS = "SAVE_KYC_DOCUMENT_SUCCESS";
export const SAVE_KYC_DOCUMENT_FAILURE = "SAVE_KYC_DOCUMENT_FAILURE";

export const DELETE_KYC_DOCUMENT_START = "DELETE_KYC_DOCUMENT_START";
export const DELETE_KYC_DOCUMENT_SUCCESS = "DELETE_KYC_DOCUMENT_SUCCESS";
export const DELETE_KYC_DOCUMENT_FAILURE = "DELETE_KYC_DOCUMENT_FAILURE";

// Vehicles related constants
export const SEARCH_VEHICLES_START = "SEARCH_VEHICLES_START";
export const SEARCH_VEHICLES_SUCCESS = "SEARCH_VEHICLES_SUCCESS";
export const SEARCH_VEHICLES_FAILURE = "SEARCH_VEHICLES_FAILURE";

export const TRENDING_VEHICLES_START = "TRENDING_VEHICLES_START";
export const TRENDING_VEHICLES_SUCCESS = "TRENDING_VEHICLES_SUCCESS";
export const TRENDING_VEHICLES_FAILURE = "TRENDING_VEHICLES_FAILURE";

export const TOP_SERVICE_LOCATIONS_START = "TOP_SERVICE_LOCATIONS_START";
export const TOP_SERVICE_LOCATIONS_SUCCESS = "TOP_SERVICE_LOCATIONS_SUCCESS";
export const TOP_SERVICE_LOCATIONS_FAILURE = "TOP_SERVICE_LOCATIONS_FAILURE";

export const FETCH_SINGLE_VEHICLE_START = "FETCH_SINGLE_VEHICLE_START";
export const FETCH_SINGLE_VEHICLE_SUCCESS = "FETCH_SINGLE_VEHICLE_SUCCESS";
export const FETCH_SINGLE_VEHICLE_FAILURE = "FETCH_SINGLE_VEHICLE_FAILURE";

export const FETCH_VEHICLE_REVIEWS_START = "FETCH_VEHICLE_REVIEWS_START";
export const FETCH_VEHICLE_REVIEWS_SUCCESS = "FETCH_VEHICLE_REVIEWS_SUCCESS";
export const FETCH_VEHICLE_REVIEWS_FAILURE = "FETCH_VEHICLE_REVIEWS_FAILURE";

// Bookings constants

export const FETCH_BOOKING_HISTORY_START = "FETCH_BOOKING_HISTORY_START";
export const FETCH_BOOKING_HISTORY_SUCCESS = "FETCH_BOOKING_HISTORY_SUCCESS";
export const FETCH_BOOKING_HISTORY_FAILURE = "FETCH_BOOKING_HISTORY_FAILURE";

export const FETCH_BOOKING_UPCOMING_START = "FETCH_BOOKING_UPCOMING_START";
export const FETCH_BOOKING_UPCOMING_SUCCESS = "FETCH_BOOKING_UPCOMING_SUCCESS";
export const FETCH_BOOKING_UPCOMING_FAILURE = "FETCH_BOOKING_UPCOMING_FAILURE";

export const FETCH_BOOKING_CANCELLED_START = "FETCH_BOOKING_CANCELLED_START";
export const FETCH_BOOKING_CANCELLED_SUCCESS =
  "FETCH_BOOKING_CANCELLED_SUCCESS";
export const FETCH_BOOKING_CANCELLED_FAILURE =
  "FETCH_BOOKING_CANCELLED_FAILURE";

export const FETCH_BOOKING_VIEW_START = "FETCH_BOOKING_VIEW_START";
export const FETCH_BOOKING_VIEW_SUCCESS = "FETCH_BOOKING_VIEW_SUCCESS";
export const FETCH_BOOKING_VIEW_FAILURE = "FETCH_BOOKING_VIEW_FAILURE";

export const CREATE_BOOKING_START = "CREATE_BOOKING_START";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";

export const BOOKING_CHECKIN_START = "BOOKING_CHECKIN_START";
export const BOOKING_CHECKIN_SUCCESS = "BOOKING_CHECKIN_SUCCESS";
export const BOOKING_CHECKIN_FAILURE = "BOOKING_CHECKIN_FAILURE";

export const BOOKING_CHECKOUT_START = "BOOKING_CHECKOUT_START";
export const BOOKING_CHECKOUT_SUCCESS = "BOOKING_CHECKOUT_SUCCESS";
export const BOOKING_CHECKOUT_FAILURE = "BOOKING_CHECKOUT_FAILURE";

export const BOOKING_CANCEL_START = "BOOKING_CANCEL_START";
export const BOOKING_CANCEL_SUCCESS = "BOOKING_CANCEL_SUCCESS";
export const BOOKING_CANCEL_FAILURE = "BOOKING_CANCEL_FAILURE";

export const BOOKING_RATE_START = "BOOKING_RATE_START";
export const BOOKING_RATE_SUCCESS = "BOOKING_RATE_SUCCESS";
export const BOOKING_RATE_FAILURE = "BOOKING_RATE_FAILURE";

// Transaction constants.

export const FETCH_ALL_TRANSACTION_START = "FETCH_ALL_TRANSACTION_START";
export const FETCH_ALL_TRANSACTION_SUCCESS = "FETCH_ALL_TRANSACTION_SUCCESS";
export const FETCH_ALL_TRANSACTION_FAILURE = "FETCH_ALL_TRANSACTION_FAILURE";

export const FETCH_SENT_PAYMENT_TRANSACTION_START =
  "FETCH_SENT_PAYMENT_TRANSACTION_START";
export const FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_SENT_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_SENT_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_SENT_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_RECEIVED_PAYMENT_TRANSACTION_START =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_START";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_SUCCESS";
export const FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE =
  "FETCH_RECEIVED_PAYMENT_TRANSACTION_FAILURE";

export const FETCH_DEPOSIT_TRANSACTION_START =
  "FETCH_DEPOSIT_TRANSACTION_START";
export const FETCH_DEPOSIT_TRANSACTION_SUCCESS =
  "FETCH_DEPOSIT_TRANSACTION_SUCCESS";
export const FETCH_DEPOSIT_TRANSACTION_FAILURE =
  "FETCH_DEPOSIT_TRANSACTION_FAILURE";

export const GET_KYC_DOCUMENT_START = "GET_KYC_DOCUMENT_START";
export const GET_KYC_DOCUMENT_SUCCESS = "GET_KYC_DOCUMENT_SUCCESS";
export const GET_KYC_DOCUMENT_FAILURE = "GET_KYC_DOCUMENT_FAILURE";

export const ADD_KYC_DOCUMENT_START = "ADD_KYC_DOCUMENT_START";
export const ADD_KYC_DOCUMENT_SUCCESS = "ADD_KYC_DOCUMENT_SUCCESS";
export const ADD_KYC_DOCUMENT_FAILURE = "ADD_KYC_DOCUMENT_FAILURE";

export const FETCH_FAQS_START = "FETCH_FAQS_START";
export const FETCH_FAQS_SUCCESS = "FETCH_FAQS_SUCCESS";
export const FETCH_FAQS_FAILURE = "FETCH_FAQS_FAILURE";
