import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./BillingAccount.css";
import {
  addBillingAccountStart,
  getBillingAccountStart,
} from "../../../store/actions/BillingAccountAction";
import { Link } from "react-router-dom";

const AddBillingAccount = (props) => {
  useEffect(() => {}, []);
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(addBillingAccountStart(inputData));
  };

  return (
    <>
      <section className="funfact-area section--padding billing-account-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h4 className="sec__title text-uppercase">
                  {t("bank_accounts")}
                </h4>
              </div>
            </div>
          </div>
          <div className="dashboard-bread-2 billing-account-tab-sec margin-top-60px form-box">
            <div className="row">
              <div className="col-lg-12 resp-mrg-btm-md">
                <div className="form-box">
                  <div className="form-title-wrap">
                    <h3 className="title">
                      {t("add_bank_account")}{" "}
                      <Link
                        to={`/billing-accounts`}
                        className="float-right btn btn-outline-primary mb-1"
                      >
                        {t("go_back")}
                      </Link>
                    </h3>
                  </div>
                  <div className="form-content">
                    <p className="text-muted add-bill-note">
                      {t("bank_accounts_note")}
                    </p>
                    <div className="contact-form-action">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("nickname")}
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  placeholder={t("nickname")}
                                  name="nickname"
                                  value={inputData.nickname}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      nickname: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("account_holder_name")}
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  placeholder={t("account_holder_name")}
                                  name="account_holder_name"
                                  value={inputData.account_holder_name}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      account_holder_name:
                                        event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("account_number")}
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("account_number")}
                                  name="account_number"
                                  autoComplete="off"
                                  value={inputData.account_number}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      account_number: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("bank_name")}
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("bank_name")}
                                  name="bank_name"
                                  autoComplete="off"
                                  value={inputData.bank_name}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      bank_name: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("ifsc_code")} ({t("optional")})
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("ifsc_code")}
                                  name="ifsc_code"
                                  autoComplete="off"
                                  value={inputData.ifsc_code}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      ifsc_code: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("swift_code")} ({t("optional")})
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("swift_code")}
                                  name="swift_code"
                                  autoComplete="off"
                                  value={inputData.swift_code}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      swift_code: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("route_number")} ({t("optional")})
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("route_number")}
                                  name="route_number"
                                  autoComplete="off"
                                  value={inputData.route_number}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      route_number: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">
                                {t("iban_number")} ({t("optional")})
                              </label>
                              <div className="form-group">
                                <span className="la la-lock form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("iban_number")}
                                  name="iban_number"
                                  autoComplete="off"
                                  value={inputData.iban_number}
                                  onChange={(event) =>
                                    setInputData({
                                      ...inputData,
                                      iban_number: event.currentTarget.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="btn-box">
                              <button
                                className="theme-btn"
                                type="submit"
                                // disabled={props.changePassword.buttonDisable}
                                // onClick={handleSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  billingAccount: state.billingAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddBillingAccount);
