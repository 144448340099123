import {
  GET_WISHLIST_START,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAILURE,
  ADD_WISHLIST_START,
  ADD_WISHLIST_SUCCESS,
  ADD_WISHLIST_FAILURE,
  CLEAR_WISHLIST_START,
  CLEAR_WISHLIST_SUCCESS,
  CLEAR_WISHLIST_FAILURE,
} from "./ActionConstant";

// Get wishlist actions.

export function getWishlistStart(data) {
  return {
    type: GET_WISHLIST_START,
    data,
  };
}

export function getWishlistSuccess(data) {
  return {
    type: GET_WISHLIST_SUCCESS,
    data,
  };
}

export function getWishlistFailure(error) {
  return {
    type: GET_WISHLIST_FAILURE,
    error,
  };
}

// Add vehicle to wishlist actions.

export function addWishlistStart(data) {
  return {
    type: ADD_WISHLIST_START,
    data,
  };
}

export function addWishlistSuccess(data) {
  return {
    type: ADD_WISHLIST_SUCCESS,
    data,
  };
}

export function addWishlistFailure(error) {
  return {
    type: ADD_WISHLIST_FAILURE,
    error,
  };
}

// CLEAR wishlist actions.

export function clearWishlistStart(data) {
  return {
    type: CLEAR_WISHLIST_START,
    data,
  };
}

export function clearWishlistSuccess(data) {
  return {
    type: CLEAR_WISHLIST_SUCCESS,
    data,
  };
}

export function clearWishlistFailure(error) {
  return {
    type: CLEAR_WISHLIST_FAILURE,
    error,
  };
}
