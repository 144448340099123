import React, { Component } from "react";
import { createBrowserHistory as createHistory } from "history";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { apiConstants } from "../Constant/constants";
import NotFoundIndex from "../Handlers/ErrorPages/NotFoundIndex";
import StaticPage from "../StaticPages/StaticPage";
import CardsIndex from "../Payments/Cards/CardsIndex";
import HomeIndex from "../Home/HomeIndex";
import MainLayout from "../Layouts/MainLayout";
import Profile from "../Account/Profile";
import EditProfile from "../Account/EditProfile";
import DeleteAccount from "../Account/DeleteAccount";
import ChangePassword from "../Account/ChangePassword";
import SettingsIndex from "../Settings/SettingsIndex";
import WalletIndex from "../Payments/Wallets/WalletIndex";
import BillingAccountsIndex from "../Payments/BillingAccounts/BillingAccountsIndex";
import AddBillingAccount from "../Payments/BillingAccounts/AddBillingAccount";
import VehiclesIndex from "../Home/Vehicles/VehiclesIndex";
import VehicleView from "../Home/Vehicles/VehicleView";
import BookingHistory from "../Bookings/BookingHistory";
import BookingView from "../Bookings/BookingView";
import NoDataFound from "../NoDataFound/NoDataFound";
import BookingCreate from "../Bookings/BookingCreate";
import InboxIndex from "../Chat/InboxIndex";
import PaymentSuccessIndex from "../Payments/PaymentSuccess/PaymentSuccessIndex";
import PaymentFailureIndex from "../Payments/PaymentFailure/PaymentFailureIndex";
import RegisterVerification from "../Auth/RegisterVerification";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../../components/translation/en.json";
import pt from "../../components/translation/pt.json";
import KycDocumentsIndex from "../Account/KycDocuments/KycDocumentsIndex";
import Logout from "../Account/Logout";

const history = createHistory();
// const $ = window.$;

setTranslations({ pt, en });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout screenProps={ScreenProps} {...props}>
        <Component {...props} />
      </Layout>
    )}
    isAuthed
  />
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authentication === true ? (
        <Layout screenProps={ScreenProps}>
          <Component {...props} authRoute={true} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    let userId = localStorage.getItem("userId");
    let accessToken = localStorage.getItem("accessToken");
    this.state = {
      loading: true,
      configLoading: true,
      authentication: userId && accessToken ? true : false,
    };

    history.listen((location, action) => {
      userId = localStorage.getItem("userId");
      accessToken = localStorage.getItem("accessToken");
      this.setState({
        loading: true,
        authentication: userId && accessToken ? true : false,
      });

      document.body.scrollTop = 0;
    });
  }

  componentDidMount() {
    this.fetchConfig();
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }

  async fetchConfig() {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      console.log("response", response);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      this.setState({ configLoading: false });
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      this.setState({ configLoading: false });
    }
    // $("#google_analytics").html(
    //   configuration.get("configData.google_analytics")
    // );

    // $("#header_scripts").html(configuration.get("configData.header_scripts"));

    // $("#body_scripts").html(configuration.get("configData.body_scripts"));
  }

  render() {
    const isLoading = this.state.configLoading;

    if (isLoading) {
      return (
        // Place content loadder here
        <div>{/* <HomeLoader></HomeLoader> */}</div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{configuration.get("configData.site_name")}</title>
          <link
            rel="icon"
            type="image/png"
            href={configuration.get("configData.site_icon")}
            sizes="16x16"
          />
          <link
            rel="apple-touch-icon"
            href={configuration.get("configData.site_icon")}
          />
          <meta
            name="description"
            content={configuration.get("configData.meta_description")}
          ></meta>
          <meta
            name="keywords"
            content={configuration.get("configData.meta_keywords")}
          ></meta>
          <meta
            name="author"
            content={configuration.get("configData.meta_author")}
          ></meta>
        </Helmet>
        <Switch>
          <AppRoute
            path={"/"}
            component={HomeIndex}
            exact
            layout={MainLayout}
          />
          <AppRoute
            path={"/register/verify"}
            component={RegisterVerification}
            layout={MainLayout}
          />
          <AppRoute
            path={"/page/:title"}
            component={StaticPage}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/profile"}
            component={Profile}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/edit-profile"}
            component={EditProfile}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/change-password"}
            component={ChangePassword}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/delete-account"}
            component={DeleteAccount}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/settings"}
            component={SettingsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/cards"}
            component={CardsIndex}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/document-verification"}
            component={KycDocumentsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/wallet"}
            component={WalletIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/withdrawals"}
            component={WalletIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/billing-accounts"}
            component={BillingAccountsIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/add-billing-account"}
            component={AddBillingAccount}
            layout={MainLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/vehicles"}
            component={VehiclesIndex}
            layout={MainLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/vehicle-details/:pro_vehicle_unique_id"}
            component={VehicleView}
            layout={MainLayout}
          />

          <AppRoute
            // authentication={this.state.authentication}
            path={"/checkout"}
            component={BookingCreate}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/bookings"}
            component={BookingHistory}
            layout={MainLayout}
          />
          <PrivateRoute
            authentication={this.state.authentication}
            path={"/booking/:booking_unique_id"}
            component={BookingView}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/inbox"}
            component={InboxIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/no-data-found"}
            component={NoDataFound}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-success"}
            component={PaymentSuccessIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/payment-failure"}
            component={PaymentFailureIndex}
            layout={MainLayout}
          />

          <PrivateRoute
            authentication={this.state.authentication}
            path={"/logout"}
            component={Logout}
            layout={MainLayout}
          />

          <Route path="*" component={NotFoundIndex} />
        </Switch>
      </>
    );
  }
}

export default App;
