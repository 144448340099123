import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import "./Settings.css";

const SettingsIndex = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <section className="funfact-area section--padding setting-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">{t("settings")}</h2>
              </div>
            </div>
          </div>
          <div className="row margin-top-30px mb-0">
            <div className="col-lg-6">
              <div className="form-box dashboard-card dashboard-bread-2">
                <div className="form-title-wrap">
                  <h3 className="title">{t("account_settings")}</h3>
                </div>
                <div className="form-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Link to={`/document-verification`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-1">
                              <i className="la la-check-circle"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">{t("kyc_documents")}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/cards`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-2">
                              <i className="la la-credit-card"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">
                                {t("payment_methods")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/edit-profile`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-3">
                              <i className="la la-edit"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">
                                {t("update_profile")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={`/billing-accounts`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-1">
                              <i className="la la-check-circle"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">
                                {t("billing_accounts")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>

                      <Link to={`/wallet`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0 mb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-1">
                              <i className="la la-check-circle"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">{t("wallet")}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-box dashboard-card dashboard-bread-2">
                <div className="form-title-wrap">
                  <h3 className="title">{t("security_settings")}</h3>
                </div>
                <div className="form-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <Link to="#">
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-1 align-items-center justify-content-between">
                            <div className="info-content">
                              <p className="info__desc">
                                {t("notification_preferances")}
                              </p>
                            </div>
                            <button
                              type="button"
                              className="btn btn-sm btn-toggle"
                              data-toggle="button"
                              aria-pressed="false"
                              autocomplete="off"
                            >
                              <div className="handle"></div>
                            </button>
                          </div>
                          <p className="d-flex align-items-center view-all font-size-12 line-height-22">
                            {t("notification_preferances_note")}
                          </p>
                        </div>
                      </Link>

                      <Link to={`/change-password`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-8">
                              <i className="la la-key"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">
                                {t("change_password")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/delete-account`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-8">
                              <i className="la la-trash"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">
                                {t("delete_account")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to={`/page/help`}>
                        <div className="icon-box icon-layout-2 dashboard-icon-box dashboard--icon-box pb-0 mb-0">
                          <div className="d-flex pb-3 align-items-center">
                            <div className="info-icon icon-element bg-8">
                              <i className="la la-question"></i>
                            </div>
                            <div className="info-content">
                              <p className="info__desc">{t("help_support")}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SettingsIndex);
