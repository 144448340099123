import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";
import { fetchFaqsStart } from "../../store/actions/VehicleAction";
import renderHTML from "react-render-html";

const HomeFaq = (props) => {

  useEffect(() => {
    props.dispatch(fetchFaqsStart());
  }, []);

  console.log(props.faqs);
  return (
    <>
      {props.faqs.loading ? (
        ""
      ) : props.faqs.data.total > 0 ? (
        <section className="faq-area section-bg section--padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center">
                  <h2 className="sec__title">{t("faq_title")}</h2>
                </div>
              </div>
            </div>

            <div className="row padding-top-60px">
              <div className="col-lg-12">
                <div className="accordion accordion-item" id="accordionExample">
                  {props.faqs.data.faqs.map((faq,index) => (
                  <div className="card" key={index}>
                    <div className="card-header" id={faq.unique_id}>
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link d-flex align-items-center justify-content-between"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#faqCollapse${faq.faq_id}`}
                          aria-expanded="true"
                          aria-controls={`faqCollapse${faq.faq_id}`}
                        >
                          <span>{faq.question}</span>
                          <i className="la la-minus"></i>
                          <i className="la la-plus"></i>
                        </button>
                      </h2>
                    </div>
                    <div
                      id={`faqCollapse${faq.faq_id}`}
                      className="collapse show"
                      aria-labelledby={faq.unique_id}
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        {renderHTML(faq.answer)}
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
                <div className="accordion-help-text pt-2">
                  <p className="font-size-14 font-weight-regular">
                    Any questions? Just visit our{" "}
                    <Link to={`/page/help`} target="_blank">{t("help_center")}</Link>
                    or{" "}
                    <Link to={`/page/contact`} target="_blank">{t("contact_us")}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  faqs: state.vehicles.faqs,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(HomeFaq);
