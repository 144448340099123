import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import moment from "moment";
import DropDownTimePicker from "../../Helper/DropDownTimePicker";
import DatePicker from "react-datepicker";
import "date-fns";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import { searchVehiclesStart } from "../../../store/actions/VehicleAction";
import "./Vehicles.css";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";
import RangeSlider from "react-bootstrap-range-slider";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { ButtonGroup, Button } from "react-bootstrap";

const VehiclesSearchOptions = (props) => {
  let autocomplete;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [inputData, setInputData] = useState({});
  const [filterPrice, setFilterPrice] = useState(10000);
  const [sortBy, setSortBy] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setInputData({
      ...inputData,
      max_price: filterPrice,
      sort_by: sortBy,
    });
    props.dispatch(
      searchVehiclesStart({
        ...inputData,
        max_price: filterPrice,
        sort_by: sortBy,
      })
    );
  };

  const handleFuelType = (event, value) => {
    event.preventDefault();
    setInputData({
      ...inputData,
      is_fuel_included: value,
    });
    props.dispatch(
      searchVehiclesStart({
        ...inputData,
        is_fuel_included: value,
      })
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData({
      ...inputData,
      latitude: 12.0,
      longitude: 72.0,
    });
    setInputData((inputData) => ({ ...inputData, [name]: value }));
  };
  const changeStartDate = (date) => {
    setStartDate(date);
    setInputData({
      ...inputData,
      start_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
    setEndDate(addDays(new Date(), 1));
  };
  const changeEndDate = (date) => {
    setEndDate(date);
    setInputData({
      ...inputData,
      end_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      console.log("data", inputData);
      console.log("lat", place.geometry.location.lat());

      setInputData({
        ...inputData,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        full_address: full_address,
      });
    });
  };
  return (
    <div className="sidebar mt-0">
      <div className="sidebar-widget">
        <h3 className="title stroke-shape">{t("search_cars")}</h3>
        <form onSubmit={handleSubmit} className="">
          <div className="sidebar-widget-item">
            <div className="contact-form-action">
              <div className="input-box">
                <label className="label-text">{t("pickup_location")}</label>
                <div className="form-group">
                  <span className="la la-map-marker form-icon"></span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("pickup_location_placeholder")}
                    name="address"
                    onFocus={renderAutoComplete}
                    ref={(ref) => (autocomplete = ref)}
                  />
                </div>
              </div>
              <div className="input-box">
                <label className="label-text">{t("pickup_date")}</label>
                <div className="form-group">
                  <span className="la la-calendar form-icon"></span>
                  <div className="form-control">
                    <DatePicker
                      selected={startDate}
                      name="start_date"
                      className="b-0"
                      minDate={new Date()}
                      excludeDates={[new Date(), subDays(new Date(), 1)]}
                      onChange={(date) => changeStartDate(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="input-box">
                <label className="label-text">{t("time")}</label>
                <div className="form-group">
                  <DropDownTimePicker
                    name="start_time"
                    value={moment().add(1, "hours").format("HH:00:00")}
                    currentTime={moment().format("HH:00:00")}
                    handleChange={handleChange}
                    className="padding-left-zero"
                  ></DropDownTimePicker>
                </div>
              </div>
              <div className="input-box">
                <label className="label-text">{t("drop_date")}</label>
                <div className="form-group">
                  <span className="la la-calendar form-icon"></span>

                  <div className="form-control">
                    <DatePicker
                      selected={endDate}
                      name="end_date"
                      className="b-0 padding-left-zero"
                      onChange={(date) => changeEndDate(date)}
                      minDate={startDate}
                      excludeDates={[new Date(), subDays(new Date(), 1)]}
                      currentTime={moment().format("HH:00:00")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-search-input-item">
            <div className="contact-form-action">
              <div className="input-box">
                <label className="label-text">{t("time")}</label>
                <div className="form-group">
                  <DropDownTimePicker
                    name="end_time"
                    value={moment().format("00:00:00")}
                    currentTime={moment().format("00:00:00")}
                    handleChange={handleChange}
                  ></DropDownTimePicker>
                </div>
              </div>
              <div className="btn-box pt-2">
                <button onClick={handleSubmit} className="theme-btn">
                  {t("search_now")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="sidebar-widget">
        <h3 className="title stroke-shape">Choose Fuel Type</h3>
        <div className="sidebar-category">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="primary"
              onClick={(event) => handleFuelType(event, 1)}
            >
              With Fuel
            </Button>
            <Button
              variant="info"
              onClick={(event) => handleFuelType(event, 0)}
            >
              Without Fuel
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="sidebar-widget">
        <h3 className="title stroke-shape">{t("filter_by_price")}</h3>
        <div className="sidebar-price-range">
          <div className="slider-range-wrap">
            <div className="price-slider-amount padding-bottom-20px">
              <label for="amount2" className="filter__label">
                {t("price")}:
              </label>
              <span>$ {filterPrice}</span>
              <RangeSlider
                value={filterPrice}
                onChange={(e) => setFilterPrice(e.target.value)}
                variant="warning"
                min="1"
                max="10000"
              />
            </div>
          </div>
          <div className="btn-box pt-2">
            <button
              className="theme-btn theme-btn-small theme-btn-transparent"
              type="button"
              onClick={handleSubmit}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <div className="sidebar-widget hidden">
        <h3 className="title stroke-shape">Filter by Rating</h3>
        <div className="sidebar-review">
          <div className="custom-checkbox">
            <input type="checkbox" id="s1" />
            <label for="s1">
              <span className="ratings d-flex align-items-center">
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <span className="color-text-3 font-size-13 ml-1">(55.590)</span>
              </span>
            </label>
          </div>
          <div className="custom-checkbox">
            <input type="checkbox" id="s2" />
            <label for="s2">
              <span className="ratings d-flex align-items-center">
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star-o"></i>
                <span className="color-text-3 font-size-13 ml-1">(40.590)</span>
              </span>
            </label>
          </div>
          <div className="custom-checkbox">
            <input type="checkbox" id="s3" />
            <label for="s3">
              <span className="ratings d-flex align-items-center">
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <span className="color-text-3 font-size-13 ml-1">(23.590)</span>
              </span>
            </label>
          </div>
          <div className="custom-checkbox">
            <input type="checkbox" id="s4" />
            <label for="s4">
              <span className="ratings d-flex align-items-center">
                <i className="la la-star"></i>
                <i className="la la-star"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <span className="color-text-3 font-size-13 ml-1">(12.590)</span>
              </span>
            </label>
          </div>
          <div className="custom-checkbox mb-0">
            <input type="checkbox" id="s5" />
            <label for="s5">
              <span className="ratings d-flex align-items-center">
                <i className="la la-star"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <i className="la la-star-o"></i>
                <span className="color-text-3 font-size-13 ml-1">(590)</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  vehiclesData: state.vehicles.vehicles,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connect(mapStateToPros, mapDispatchToProps)(VehiclesSearchOptions));
