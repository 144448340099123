import {
  SEARCH_VEHICLES_START,
  SEARCH_VEHICLES_SUCCESS,
  SEARCH_VEHICLES_FAILURE,
  TRENDING_VEHICLES_START,
  TRENDING_VEHICLES_SUCCESS,
  TRENDING_VEHICLES_FAILURE,
  TOP_SERVICE_LOCATIONS_START,
  TOP_SERVICE_LOCATIONS_SUCCESS,
  TOP_SERVICE_LOCATIONS_FAILURE,
  FETCH_SINGLE_VEHICLE_START,
  FETCH_SINGLE_VEHICLE_SUCCESS,
  FETCH_SINGLE_VEHICLE_FAILURE,
  FETCH_VEHICLE_REVIEWS_FAILURE,
  FETCH_VEHICLE_REVIEWS_SUCCESS,
  FETCH_VEHICLE_REVIEWS_START,
  FETCH_FAQS_START,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  vehiclesInput: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    successData: {},
  },
  vehicles: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  vehicleData: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  vehicleReviews: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  serviceLocations: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
  faqs: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
};

const VehicleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_VEHICLES_START:
      return {
        ...state,
        vehicles: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };
    case SEARCH_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SEARCH_VEHICLES_FAILURE:
      return {
        ...state,
        vehicles: {
          data: {},
          loading: true,
          error: false,
          error: action.error,
          inputData: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case TRENDING_VEHICLES_START:
      return {
        ...state,
        vehicles: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case TRENDING_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case TRENDING_VEHICLES_FAILURE:
      return {
        ...state,
        vehicles: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case TOP_SERVICE_LOCATIONS_START:
      return {
        ...state,
        serviceLocations: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case TOP_SERVICE_LOCATIONS_SUCCESS:
      return {
        ...state,
        serviceLocations: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case TOP_SERVICE_LOCATIONS_FAILURE:
      return {
        ...state,
        serviceLocations: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_VEHICLE_START:
      return {
        ...state,
        vehicleData: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_SINGLE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicleData: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_SINGLE_VEHICLE_FAILURE:
      return {
        ...state,
        vehicleData: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    case FETCH_VEHICLE_REVIEWS_START:
      return {
        ...state,
        vehicleReviews: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_VEHICLE_REVIEWS_SUCCESS:
      return {
        ...state,
        vehicleReviews: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_VEHICLE_REVIEWS_FAILURE:
      return {
        ...state,
        vehicleReviews: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_FAQS_START:
      return {
        ...state,
        faqs: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_FAQS_SUCCESS:
      return {
        ...state,
        faqs: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_FAQS_FAILURE:
      return {
        ...state,
        faqs: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    default:
      return state;
  }
};

export default VehicleReducer;
