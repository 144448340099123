import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";

const FooterIndex = (props) => {
  return (
    <>
      <section className="footer-area section-bg padding-top-100px padding-bottom-30px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 responsive-column">
              <div className="footer-item">
                <div className="footer-logo padding-bottom-30px">
                  <a href="index.html" className="foot__logo">
                    <img
                      style={{ width: "12rem" }}
                      src={
                        configuration.get("configData.site_logo")
                          ? configuration.get("configData.site_logo")
                          : window.location.origin + "/favicon.png"
                      }
                      alt={configuration.get("configData.site_name")}
                    ></img>
                  </a>
                </div>
                <p className="footer__desc">
                  {configuration.get("configData.tag_name")}
                </p>
                <ul className="list-items pt-3">
                  <li>{configuration.get("configData.contact_address")}</li>
                  <li>
                    <a
                      href={
                        `tel:` + configuration.get("configData.contact_mobile")
                      }
                    >
                      {configuration.get("configData.contact_mobile")}
                    </a>
                  </li>

                  <li>
                    <a
                      href={
                        `mailto:` +
                        configuration.get("configData.contact_email")
                      }
                    >
                      {configuration.get("configData.contact_email")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  {configuration.get("configData.footer_heading_1")
                    ? configuration.get("configData.footer_heading_1")
                    : t("company")}
                </h4>
                <ul className="list-items list--items">
                  {configuration.get("configData.footer_pages1").length > 0
                    ? configuration
                        .get("configData.footer_pages1")
                        .map((static_page, index) => (
                          <li key={`page1${index}`}>
                            <Link
                              to={`/page/${static_page.static_page_unique_id}`}
                              key={static_page.static_page_unique_id}
                              target="_blank"
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  {configuration.get("configData.footer_heading_2")
                    ? configuration.get("configData.footer_heading_2")
                    : t("discover")}
                </h4>
                <ul className="list-items list--items">
                  {configuration.get("configData.footer_pages2").length > 0
                    ? configuration
                        .get("configData.footer_pages2")
                        .map((static_page, index) => (
                          <li key={`page1${index}`}>
                            <Link
                              to={`/page/${static_page.static_page_unique_id}`}
                              key={static_page.static_page_unique_id}
                              target="_blank"
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 responsive-column">
              <div className="footer-item">
                <h4
                  className="title curve-shape pb-3 margin-bottom-20px"
                  data-text="curvs"
                >
                  {configuration.get("configData.footer_heading_3")
                    ? configuration.get("configData.footer_heading_3")
                    : t("more")}
                </h4>
                <ul className="list-items list--items">
                  {configuration.get("configData.footer_pages2").length > 0
                    ? configuration
                        .get("configData.footer_pages2")
                        .map((static_page, index) => (
                          <li key={`page1${index}`}>
                            <Link
                              to={`/page/${static_page.static_page_unique_id}`}
                              key={static_page.static_page_unique_id}
                              target="_blank"
                            >
                              {static_page.title}
                            </Link>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="term-box footer-item">
                <ul className="list-items list--items d-flex align-items-center">
                  <li>
                    <Link to={`/page/terms`} target="_blank">{t("terms_conditions")}</Link>
                  </li>
                  <li>
                    <Link to={`/page/privacy`} target="_blank">{t("privacy_policy")}</Link>
                  </li>
                  <li>
                    <Link to={`/page/help`} target="_blank">{t("help_center")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-social-box text-right">
                <ul className="social-profile">
                  {configuration.get("configData.facebook_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.facebook_link")}
                        target="_blank"
                      >
                        <i className="lab la-facebook-f"></i>
                      </a>
                    </li>
                  ) : null}
                  {configuration.get("configData.twitter_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.twitter_link")}
                        target="_blank"
                      >
                        <i className="lab la-twitter"></i>
                      </a>
                    </li>
                  ) : null}
                  {configuration.get("configData.instagram_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.instagram_link")}
                        target="_blank"
                      >
                        <i className="lab la-instagram"></i>
                      </a>
                    </li>
                  ) : null}

                  {configuration.get("configData.youtube_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.youtube_link")}
                        target="_blank"
                      >
                        <i className="lab la-youtube"></i>
                      </a>
                    </li>
                  ) : null}

                  {configuration.get("configData.linkedin_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.linkedin_link")}
                        target="_blank"
                      >
                        <i className="lab la-linkedin-in"></i>
                      </a>
                    </li>
                  ) : null}

                  {configuration.get("configData.pinterest_link") ? (
                    <li>
                      <a
                        href={configuration.get("configData.pinterest_link")}
                        target="_blank"
                      >
                        <i className="lab la-pinterest"></i>
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section-block mt-4"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="copy-right padding-top-30px">
                <p className="copy__desc">
                  {configuration.get("configData.copyright_content")
                    ? configuration.get("configData.copyright_content")
                    : t("copyright_content")}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="copy-right-content d-flex align-items-center justify-content-end padding-top-30px">
                <h3 className="title font-size-15 pr-2">{t("we_accept")}</h3>
                <img
                  src={
                    configuration.get("configData.footer_payment_accept_image")
                      ? configuration.get(
                          "configData.footer_payment_accept_image"
                        )
                      : window.location.origin + "/images/payment-accept.png"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(FooterIndex);
