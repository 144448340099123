import React from "react"
import ContentLoader from "react-content-loader"

const KYCLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={450}
    viewBox="0 0 1200 450"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="6" y="19" rx="0" ry="0" width="10" height="200" /> 
    <rect x="7" y="18" rx="0" ry="0" width="573" height="10" /> 
    <rect x="570" y="20" rx="0" ry="0" width="10" height="198" /> 
    <rect x="8" y="209" rx="0" ry="0" width="571" height="10" /> 
    <rect x="27" y="41" rx="0" ry="0" width="80" height="10" /> 
    <rect x="27" y="58" rx="0" ry="0" width="154" height="6" /> 
    <rect x="14" y="79" rx="0" ry="0" width="558" height="5" /> 
    <rect x="38" y="99" rx="0" ry="0" width="246" height="96" /> 
    <rect x="303" y="100" rx="0" ry="0" width="246" height="96" /> 
    <rect x="6" y="235" rx="0" ry="0" width="10" height="200" /> 
    <rect x="7" y="234" rx="0" ry="0" width="573" height="10" /> 
    <rect x="570" y="236" rx="0" ry="0" width="10" height="198" /> 
    <rect x="8" y="425" rx="0" ry="0" width="571" height="10" /> 
    <rect x="27" y="257" rx="0" ry="0" width="80" height="10" /> 
    <rect x="27" y="274" rx="0" ry="0" width="154" height="6" /> 
    <rect x="14" y="295" rx="0" ry="0" width="558" height="5" /> 
    <rect x="38" y="315" rx="0" ry="0" width="246" height="96" /> 
    <rect x="303" y="316" rx="0" ry="0" width="246" height="96" />
  </ContentLoader>
)

export default KYCLoader;