import {
  GET_WISHLIST_START,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAILURE,
  ADD_WISHLIST_START,
  ADD_WISHLIST_SUCCESS,
  ADD_WISHLIST_FAILURE,
  CLEAR_WISHLIST_START,
  CLEAR_WISHLIST_SUCCESS,
  CLEAR_WISHLIST_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  addWishlist: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  wishlists: {
    data: {},
    loading: true,
    error: false,
  },
  clearWishlistInput: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const WishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WISHLIST_START:
      return {
        ...state,
        addWishlist: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait",
          buttonDisable: true,
        },
      };
    case ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        addWishlist: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case ADD_WISHLIST_FAILURE:
      return {
        ...state,
        addWishlist: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case GET_WISHLIST_START:
      return {
        ...state,
        wishlists: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlists: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case GET_WISHLIST_FAILURE:
      return {
        ...state,
        wishlists: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case CLEAR_WISHLIST_START:
      return {
        ...state,
        clearWishlistInput: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case CLEAR_WISHLIST_SUCCESS:
      return {
        ...state,
        clearWishlistInput: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case CLEAR_WISHLIST_FAILURE:
      return {
        ...state,
        clearWishlistInput: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default WishlistReducer;
