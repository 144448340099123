import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./Wallet.css";
import { fetchWalletDetailsStart } from "../../../store/actions/WalletAction";
import { fetchAllTransactionStart } from "../../../store/actions/TransactionAction";
import WalletHistory from "./WalletHistory";

const WalletIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
  }, []);

  const [withdrawModal, setWithdrawModal] = useState(false);

  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };
  return (
    <>
      {props.wallet.loading ? (
        "Loading..."
      ) : (
        <section className="funfact-area section--padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center">
                  <h2 className="sec__title">{t("wallet")}</h2>
                </div>
              </div>
            </div>
            <div className="counter-box counter-box-2 margin-top-20px mb-0">
              <div className="row">
                <div className="col-lg-4 responsive-column">
                  <div className="counter-item counter-item-layout-2 d-flex">
                    <div className="counter-icon flex-shrink-0">
                      <i className="la la-wallet"></i>
                    </div>
                    <div className="counter-content">
                      <div>
                        <span
                          className="counter"
                          data-from="0"
                          data-to="200"
                          data-refresh-interval="5"
                        >
                          {props.wallet.data.user_wallet.total_formatted}
                        </span>
                      </div>
                      <p className="counter__title">{t("total")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 responsive-column">
                  <div className="counter-item counter-item-layout-2 d-flex">
                    <div className="counter-icon flex-shrink-0">
                      <i className="la la-fist-raised"></i>
                    </div>
                    <div className="counter-content">
                      <div>
                        <span
                          className="counter"
                          data-from="0"
                          data-to="3"
                          data-refresh-interval="5"
                        >
                          {props.wallet.data.user_wallet.remaining_formatted}
                        </span>
                      </div>
                      <p className="counter__title">{t("remaining")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 responsive-column">
                  <div className="counter-item counter-item-layout-2 d-flex">
                    <div className="counter-icon flex-shrink-0">
                      <i className="la la-tint"></i>
                    </div>
                    <div className="counter-content">
                      <div>
                        <span
                          className="counter"
                          data-from="0"
                          data-to="400"
                          data-refresh-interval="5"
                        >
                          {props.wallet.data.user_wallet.used_formatted}
                        </span>
                      </div>
                      <p className="counter__title">{t("used")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <WalletHistory></WalletHistory>
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
