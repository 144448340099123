import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import "./Inbox.css";

const InboxIndex = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <section className="funfact-area section--padding inbox-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">Inbox</h2>
              </div>
            </div>
          </div>
          <div className="row margin-top-60px mb-0 row-no-padding">
            <div className="col-lg-4 padding-right-none">
              <div className="people-list dashboard-bread-2" id="style-2">
                <div className="search" style={{ display: "none" }}>
                  <input type="text" placeholder="search" />
                  <i className="fa fa-search"></i>
                </div>
                <div className="people-list-header">
                  <h3 className="title font-size-24 text-white">Chat List</h3>
                </div>
                <ul className="list">
                  <li className="active">
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Beno Darry</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Jerif Daniel</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                        left 7 mins ago
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Jeno</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Suderson</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Ruban</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">BenWhiteSon</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                        left 30 mins ago
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Beaulet</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                        left 10 hours ago
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Vinoth</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Pinto</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="offline" />{" "}
                        offline since Oct 28
                      </div>
                    </div>
                  </li>
                  <li>
                    <img
                      src="assets/images/placeholder-profile.jpg"
                      alt="no image"
                      className="chat-profile-img"
                    />
                    <div className="about">
                      <div className="name">Rufus</div>
                      <div className="status">
                        <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                        online
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 padding-left-none">
              <div className="chat">
                <div className="chat-header clearfix">
                  <img
                    src="assets/images/placeholder-profile.jpg"
                    alt="no image"
                    className="chat-profile-img"
                  />
                  <div className="chat-about">
                    <div className="chat-with">Chat with Beno Darry</div>
                    <div className="chat-num-messages">
                      already 1 902 messages
                    </div>
                  </div>
                  <i className="fa fa-star"></i>
                </div>
                <div className="chat-history" id="style-2">
                  <ul>
                    <li>
                      <div className="message-data align-right">
                        <span className="message-data-time">
                          10:10 AM, Today
                        </span>{" "}
                        &nbsp; &nbsp;
                        <span className="message-data-name">Jeffy</span>{" "}
                        <i className="fa fa-circle me"></i>
                      </div>
                      <div className="message other-message float-right">
                        Hi Beno Darry, how are you? How is the project coming
                        along?
                      </div>
                    </li>
                    <li>
                      <div className="message-data">
                        <span className="message-data-name">
                          <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                          Beno Darry
                        </span>
                        <span className="message-data-time">
                          10:12 AM, Today
                        </span>
                      </div>
                      <div className="message my-message">
                        Are we meeting today? Project has been already finished
                        and I have results to show you.
                      </div>
                    </li>
                    <li>
                      <div className="message-data align-right">
                        <span className="message-data-time">
                          10:14 AM, Today
                        </span>{" "}
                        &nbsp; &nbsp;
                        <span className="message-data-name">Jeffy</span>{" "}
                        <i className="fa fa-circle me"></i>
                      </div>
                      <div className="message other-message float-right">
                        Well I am not sure. The rest of the team is not here
                        yet. Maybe in an hour or so? Have you faced any problems
                        at the last phase of the project?
                      </div>
                    </li>
                    <li>
                      <div className="message-data">
                        <span className="message-data-name">
                          <FontAwesomeIcon icon={faCircle} className="online" />{" "}
                          Beno Darry
                        </span>
                        <span className="message-data-time">
                          10:20 AM, Today
                        </span>
                      </div>
                      <div className="message my-message">
                        Actually everything was fine. I'm very excited to show
                        this to our team.
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  <textarea
                    name="message-to-send"
                    id="message-to-send"
                    placeholder="Type your message"
                    rows="3"
                  ></textarea>
                  <div className="chat-send-btm-sec">
                    <button
                      className="theme-btn theme-btn-small submit-btn"
                      type="submit"
                    >
                      <i className="la la-paper-plane mr-1"></i>Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(InboxIndex);
