import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import { Image } from "react-bootstrap";
import KYCLoader from "../../Handlers/Loaders/KYCLoader";
import "./KycDocuments.css";
import {
  addKycDocumentStart,
  getKycDocumentStart,
} from "../../../store/actions/KycDocumentAction";

const KycDocumentsIndex = (props) => {
  useEffect(() => {
    props.dispatch(getKycDocumentStart());
  }, []);

  const [inputData, setInputData] = useState({});

  const [image, setImage] = useState({});

  const [uploadDocumentID, setUploadDocumentID] = useState(null);

  const handleChangeImage = (event, doc) => {
    if (event.currentTarget.type === "file") {
      setInputData({
        ...inputData,
        document_file: event.currentTarget.files[0],
        kyc_document_id: doc.kyc_document_id,
      });
      let reader = new FileReader();
      let file = event.currentTarget.files[0];

      reader.onloadend = () => {
        setImage({ ...image, [doc.kyc_document_id]: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (event, doc) => {
    event.preventDefault();
    setUploadDocumentID(doc.kyc_document_id);
    props.dispatch(addKycDocumentStart(inputData));
  };
  return (
    <>
      <section className="funfact-area section--padding upload-doc-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">{t("kyc_documents")}</h2>
              </div>
            </div>
          </div>
          {props.kycDocumentList.loading ? (
            <KYCLoader></KYCLoader>
          ) : props.kycDocumentList.data.kyc_documents.length > 0 ? (
            props.kycDocumentList.data.kyc_documents.map((document) => (
              <>
                <div className="form-box margin-top-60px mb-0">
                  <div className="form-title-wrap">
                    <h3 className="title">
                      <i className="la la-photo mr-2 text-gray"></i>
                      {document.name}
                      <span className="float-right text-info">
                        {document.user_kyc_document_status_formatted}
                      </span>
                    </h3>
                    <p className="font-size-13 line-height-20 mb-0">
                      {document.description}
                    </p>
                  </div>
                  <div className="row">
                    {document.is_delete_edit_option ? (
                      <div className="col-lg-6">
                        <div className="upload-doc-card">
                          <div className="upload-doc-box">
                            <img
                              src={document.picture}
                              alt={document.name}
                              className="doc-upload-img"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-lg-6">
                      <div className="form-content contact-form-action">
                        <form method="post" className="row">
                          <div className="col-lg-12">
                            <div className="file-upload-wrap">
                              <input
                                type="file"
                                id={document.kyc_document_id}
                                name={document.kyc_document_id}
                                className="multi file-upload-input with-preview"
                                onChange={(event) =>
                                  handleChangeImage(event, document)
                                }
                                accept="image/*"
                              />
                              <span className="file-upload-text">
                                {image[document.kyc_document_id] !==
                                undefined ? (
                                  <Image
                                    src={image[document.kyc_document_id]}
                                    className="doc-upload-img"
                                  />
                                ) : document.user_kyc_document.document_file !==
                                  undefined ? (
                                  <Image
                                    src={
                                      document.user_kyc_document.document_file
                                    }
                                    className="doc-upload-img"
                                  />
                                ) : (
                                  <span>
                                    <i className="la la-upload mr-2"></i>
                                    {t("kyc_drag_upload_note")}
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {document.is_delete_edit_option ? (
                  <div className="upload-doc-btm-sec mt-4">
                    <button
                      className="theme-btn"
                      type="submit"
                      onClick={(event) => handleSubmit(event, document)}
                      disabled={
                        uploadDocumentID === document.kyc_document_id
                          ? true
                          : false
                      }
                    >
                      {uploadDocumentID === document.kyc_document_id
                        ? props.addKycDocInput.loadingButtonContent
                        : t("send_for_approval")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))
          ) : null}
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  kycDocumentList: state.kycDocument.kycDocumentList,
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(KycDocumentsIndex);
