import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./StaticPage.css";
import { fetchSinglePageStart } from "../../store/actions/PageAction";
import StaticLoader from "../Handlers/Loaders/StaticLoader";

const StaticPage = (props) => {
  useEffect(() => {
    if (props.page.loading)
      props.dispatch(
        fetchSinglePageStart({ unique_id: props.match.params.title })
      );
  }, []);
  return (
    <>
      {props.page.loading ? (
        <StaticLoader></StaticLoader>
      ) : (
        <section className="service-area section--padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading">
                  <h2 className="sec__title">{props.page.data.title}</h2>
                  <p>{props.page.data.updated}</p>
                </div>
                <hr></hr>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h3 className="title font-size-24 align-center-resp"></h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.page.data.description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  page: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticPage);
