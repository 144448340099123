import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./BillingAccount.css";
import {
  addBillingAccountStart,
  deleteBillingAccountStart,
  getBillingAccountStart,
  makeDefaultBillingAccountStart,
} from "../../../store/actions/BillingAccountAction";
import { Link } from "react-router-dom";

const BillingAccountsIndex = (props) => {
  useEffect(() => {
    props.dispatch(getBillingAccountStart());
  }, []);
  const [inputData, setInputData] = useState({});

  const handleDelete = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      deleteBillingAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };
  const handleMarkDefault = (event, user_billing_account_id) => {
    event.preventDefault();
    props.dispatch(
      makeDefaultBillingAccountStart({
        user_billing_account_id: user_billing_account_id,
      })
    );
  };

  return (
    <>
      <section className="funfact-area section--padding billing-account-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title text-uppercase">
                  {t("bank_accounts")}
                </h2>
              </div>
            </div>
          </div>
          <div className="dashboard-bread-2 billing-account-tab-sec margin-top-60px form-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="counter-box counter-box-2 mb-0">
                  <div className="section-tab text-center">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link d-flex align-items-center active"
                          id="billing-accounts-tab"
                          data-toggle="tab"
                          href="#billing-accounts"
                          role="tab"
                          aria-controls="billing-accounts"
                          aria-selected="true"
                        >
                          <i className="la la-tint mr-1"></i>
                          {t("billing_accounts")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link d-flex align-items-center"
                          href={"/add-billing-account"}
                        >
                          <i className="la la-clipboard-check mr-1"></i>
                          {t("add_bank_account")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content search-fields-container"
                    id="myTabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="billing-accounts"
                      role="tabpanel"
                      aria-labelledby="billing-accounts-tab"
                    >
                      {props.billingAccounts.loading ? (
                        t("loading")
                      ) : props.billingAccounts.data.billing_accounts.length >
                        0 ? (
                        <div className="table-form table-responsive">
                          {props.billingAccounts.data.billing_accounts.map(
                            (value, index) => {
                              <div>Hello</div>;
                            }
                          )}
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">{t("s_no")}</th>
                                <th scope="col">{t("nickname")}</th>
                                <th scope="col">{t("name")}</th>
                                <th scope="col">{t("account_number")}</th>
                                <th scope="col">{t("bank_name")}</th>
                                <th scope="col">{t("ifsc_code")}</th>
                                <th scope="col">{t("iban_number")}</th>
                                <th scope="col">{t("route_number")}</th>
                                <th scope="col">{t("action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {props.billingAccounts.data.billing_accounts.map(
                                (value, index) => {
                                  return [
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {value.nickname}{" "}
                                        {value.is_default == 1 ? (
                                          <i class="las la-check-circle text-success"></i>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>{value.account_holder_name}</td>
                                      <td>
                                        <span className="text-info">
                                          {value.account_number}{" "}
                                        </span>
                                        <br></br>
                                        <span className="">
                                          {t("bank_name")}: {value.bank_name}
                                        </span>
                                      </td>
                                      <td>{value.swift_code}</td>
                                      <td>{value.ifsc_code}</td>
                                      <td>{value.iban_number}</td>
                                      <td>{value.route_number}</td>
                                      <td>
                                        <div className="dropdown dropdown-contain">
                                          <a
                                            className="dropdown-toggle dropdown-btn dropdown--btn text-primary"
                                            href="#"
                                            role="button"
                                            data-toggle="dropdown"
                                          >
                                            <span className="">
                                              {t("actions")}
                                            </span>
                                          </a>
                                          <div className="dropdown-menu dropdown-menu-wrap">
                                            <div className="dropdown-item">
                                              <div className="checkbox-wrap">
                                                <Link
                                                  to="#"
                                                  className="text-muted"
                                                  onClick={(event) =>
                                                    handleMarkDefault(
                                                      event,
                                                      value.user_billing_account_id
                                                    )
                                                  }
                                                >
                                                  {value.is_default == 0 ? (
                                                    <div className="custom-checkbox">
                                                      <i class="las la-check-circle"></i>
                                                      {t("mark_as_default")}
                                                    </div>
                                                  ) : null}
                                                </Link>
                                                <Link
                                                  to="#"
                                                  className="text-muted"
                                                  onClick={(event) =>
                                                    handleDelete(
                                                      event,
                                                      value.user_billing_account_id
                                                    )
                                                  }
                                                >
                                                  <div className="custom-checkbox">
                                                    <i class="las la-trash 32px"></i>
                                                    {t("delete")}
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>,
                                  ];
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        "No data found"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  billingAccounts: state.billingAccount.billingAccounts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(BillingAccountsIndex);
