import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHighlighter,
  faInbox,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import LoginModal from "../../Auth/LoginModal";
import { Button } from "react-bootstrap";
import RegisterModal from "../../Auth/RegisterModal";

const HeaderIndex = (props) => {

  const [showLogin, setShowLogin] = useState(false);
  const handleLoginClose = () => setShowLogin(false);
  const [showRegister, setShowRegister] = useState(false);
  const handleRegisterClose = () => setShowRegister(false);

  const handleLoginShow = () => {
    setShowLogin(true);
  };
  const handleRegisterShow = () => {
    setShowRegister(true);
  };
  return (
    <header className="header-area">
      <div className="header-top-bar padding-right-100px padding-left-100px">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="header-top-content">
                <div className="header-left">
                  <ul className="list-items">
                    {configuration.get("configData.contact_mobile") ? (
                      <li>
                        <a
                          href={
                            `tel:` +
                            configuration.get("configData.contact_mobile")
                          }
                        >
                          <i className="la la-phone mr-1"></i>
                          {configuration.get("configData.contact_mobile")}
                        </a>
                      </li>
                    ) : null}
                    {configuration.get("configData.contact_email") ? (
                      <li>
                        <a
                          href={
                            `mailto:` +
                            configuration.get("configData.contact_email")
                          }
                        >
                          <i className="la la-envelope mr-1"></i>
                          {configuration.get("configData.contact_email")}
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-top-content">
                <div className="header-right d-flex align-items-center justify-content-end">
                  {localStorage.getItem("userLoginStatus") ? (
                    <div className="header-right-action">
                      <div className="main-menu-content pt-0">
                        <nav>
                          <ul>
                            <li>
                              <Link to="#" className="pb-0">
                                Welcome {localStorage.getItem("name")}.,
                                <i className="la la-angle-down"></i>
                              </Link>
                              <ul className="dropdown-menu-item">
                                <li>
                                  <Link to="/profile">Profile</Link>
                                </li>
                                <li>
                                  <Link to="/logout">Logout</Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  ) : (
                    <div className="header-right-action">
                      <button
                        className="theme-btn theme-btn-small theme-btn-transparent mr-1"
                        onClick={handleRegisterShow}
                      >
                        {t("signup")}
                      </button>
                      <button
                        className="theme-btn theme-btn-small"
                        onClick={handleLoginShow}
                      >
                        {t("login")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-menu-wrapper padding-right-100px padding-left-100px border-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="menu-wrapper">
                <a href="#" className="down-button">
                  <i className="la la-angle-down"></i>
                </a>
                <div className="logo">
                  <Link to="/">
                    <img
                      style={{ width: "9rem" }}
                      src={
                        configuration.get("configData.site_logo")
                          ? configuration.get("configData.site_logo")
                          : window.location.origin + "/favicon.png"
                      }
                      alt={configuration.get("configData.site_name")}
                    />
                  </Link>
                  <div className="menu-toggler">
                    <i className="la la-bars"></i>
                    <i className="la la-times"></i>
                  </div>
                </div>
                <div className="main-menu-content">
                  <nav>
                    <ul>
                      <li>
                        <Link to={`/`}>
                          {" "}
                          <FontAwesomeIcon icon={faHome} color="gray" />{" "}
                          {t("home")}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to={`/inbox`}>
                          <FontAwesomeIcon icon={faInbox} color="gray" />{" "}
                          {t("inbox")}
                        </Link>
                      </li> */}
                      <li>
                        <Link to={`/bookings`}>
                          <FontAwesomeIcon icon={faHighlighter} color="gray" />{" "}
                          {t("bookings")}
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="nav-btn">
                  <a
                    target="_blank"
                    href={
                      configuration.get("configData.provider_signup_url")
                        ? configuration.get("configData.provider_signup_url")
                        : "#"
                    }
                    className="theme-btn"
                  >
                    {t("become_a_provider")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal showLogin={showLogin} handleLoginClose={handleLoginClose} />
      <RegisterModal
        showRegister={showRegister}
        handleRegisterClose={handleRegisterClose}
      />
    </header>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(HeaderIndex);
