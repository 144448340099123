import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import moment from "moment";
import DropDownTimePicker from "../../Helper/DropDownTimePicker";
import DatePicker from "react-datepicker";
import "date-fns";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import { searchVehiclesStart } from "../../../store/actions/VehicleAction";
import "./Vehicles.css";
import VehicleCardSec from "./VehicleCardSec";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";
import VehiclesSearchOptions from "./VehiclesSearchOptions";
import {useLocation} from "react-router-dom";


const VehiclesIndex = (props) => {
  const [inputData, setInputData] = useState({});
  let location = useLocation();
  let data = location.state && location.state.inputData;
  
  async function getData() {
   
    setInputData(JSON.parse(localStorage.getItem("search_input")));
  }

  useEffect(() => {
    getData();
     props.dispatch(searchVehiclesStart(data));
  }, []);

  let autocomplete;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(searchVehiclesStart(inputData));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData({
      ...inputData,
      latitude: 12.0,
      longitude: 72.0,
    });
    setInputData((inputData) => ({ ...inputData, [name]: value }));
  };
  const changeStartDate = (date) => {
    setStartDate(date);
    setInputData({
      ...inputData,
      start_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
    setEndDate(addDays(new Date(), 1));
  };
  const changeEndDate = (date) => {
    setEndDate(date);
    setInputData({
      ...inputData,
      end_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      console.log("data", inputData);
      console.log("lat", place.geometry.location.lat());

      setInputData({
        ...inputData,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        full_address: full_address,
      });
    });
  };
  return (
    <>
      <section className="card-area section--padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="filter-wrap margin-bottom-30px">
                <div className="filter-top d-flex align-items-center justify-content-between pb-4">
                  <div>
                    <h3 className="title font-size-24">
                      {props.vehiclesData.data.total
                        ? props.vehiclesData.data.total
                        : 0}{" "}
                      Cars found
                    </h3>
                    <p className="font-size-14">
                      <span className="mr-1 pt-1">Book with confidence:</span>
                      No car booking fees
                    </p>
                  </div>
                  <div className="layout-view d-flex align-items-center hidden">
                    <a
                      href="car-grid.html"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Grid View"
                    >
                      <i className="la la-th-large"></i>
                    </a>
                    <a
                      href="car-list.html"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="List View"
                      className="active"
                    >
                      <i className="la la-th-list"></i>
                    </a>
                  </div>
                </div>
                <div className="filter-bar d-flex align-items-center justify-content-between hidden">
                  <div className="filter-bar-filter d-flex flex-wrap align-items-center">
                    <div className="filter-option">
                      <h3 className="title font-size-16">Filter by:</h3>
                    </div>
                    <div className="filter-option">
                      <div className="dropdown dropdown-contain">
                        <a
                          className="dropdown-toggle dropdown-btn dropdown--btn"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                        >
                          Categories
                        </a>
                        <div className="dropdown-menu dropdown-menu-wrap">
                          <div className="dropdown-item">
                            <div className="checkbox-wrap">
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb1" />
                                <label for="catChb1">Convertibles</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb2" />
                                <label for="catChb2">Coupes</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb3" />
                                <label for="catChb3">Minivans</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb4" />
                                <label for="catChb4">Sedan</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb5" />
                                <label for="catChb5">SUV'S</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb6" />
                                <label for="catChb6">Trucks</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb7" />
                                <label for="catChb7">Wagons</label>
                              </div>
                              <div className="custom-checkbox">
                                <input type="checkbox" id="catChb8" />
                                <label for="catChb8">Compact Elite</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-contain dropdown bootstrap-select select-contain-select dropup">
                    <select className="select-contain-select">
                      <option value="1">Short by default</option>
                      <option value="2">New Car</option>
                      <option value="3">Popular Car</option>
                      <option value="4">Popular Cruise</option>
                      <option value="5">Price: low to high</option>
                      <option value="6">Price: high to low</option>
                      <option value="7">A to Z</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <VehiclesSearchOptions />
            </div>
            <div className="col-lg-8">
              {props.vehiclesData.data.pro_vehicles &&
              props.vehiclesData.data.pro_vehicles.length > 0
                ? props.vehiclesData.data.pro_vehicles.map(
                    (vehicleInfo, index) => {
                      return [
                        <VehicleCardSec
                          vehicleInfo={vehicleInfo}
                        ></VehicleCardSec>,
                      ];
                    }
                  )
                : "No Cars Found"}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="btn-box mt-3 text-center">
                <button type="button" className="theme-btn">
                  <i className="la la-refresh mr-1"></i>Load More
                </button>
                <p className="font-size-13 pt-2">Showing 1 - 6 of 44 Cars</p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  vehiclesData: state.vehicles.vehicles,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connect(mapStateToPros, mapDispatchToProps)(VehiclesIndex));
