import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";

const RegisterVerification = (props) => {
  useEffect(() => {}, []);
  return <>Hello World...</>;
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(RegisterVerification);
