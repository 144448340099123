import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./PaymentFailure.css";
const PaymentFailureIndex = (props) => {
  return (
    <>
      <section className="payment-failure-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="payment-failure-img-sec">
                <img
                  src="assets/images/payment-failure.svg"
                  alt="user-image"
                  className="payment-failure-img"
                />
              </div>
              <h1 className="payment-title text-danger">
                Your Transaction Was Failed!
              </h1>
              <button className="btn btn-danger mt-4 w-200">Go Home</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  cards: state.cards.cardList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(PaymentFailureIndex);
