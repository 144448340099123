import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { fetchVehicleReviewsStart } from "../../../store/actions/VehicleAction";

const VehicleReviews = (props) => {
  useEffect(() => {
    if (props.vehicleReviews.loading)
      props.dispatch(
        fetchVehicleReviewsStart({
          pro_vehicle_unique_id: props.pro_vehicle_unique_id,
        })
      );
  }, []);
  return (
    <>
      <div id="reviews_section" className="page-scroll">
        <div className="single-content-item padding-top-40px padding-bottom-40px">
          <h3 className="title font-size-20">{t("reviews")}</h3>
          <div
            className="review-container padding-top-30px"
            style={{ display: "none" }}
          >
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="review-summary">
                  <h2>
                    4.5<span>/5</span>
                  </h2>
                  <p>Excellent</p>
                  <span>Based on 4 reviews</span>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="review-bars">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="progress-item">
                        <h3 className="progressbar-title">Service</h3>
                        <div className="progressbar-content line-height-20 d-flex align-items-center justify-content-between">
                          <div className="progressbar-box flex-shrink-0">
                            <div
                              className="progressbar-line"
                              data-percent="70%"
                            >
                              <div className="progressbar-line-item bar-bg-1"></div>
                            </div>
                          </div>
                          <div className="bar-percent">4.6</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="progress-item">
                        <h3 className="progressbar-title">Location</h3>
                        <div className="progressbar-content line-height-20 d-flex align-items-center justify-content-between">
                          <div className="progressbar-box flex-shrink-0">
                            <div
                              className="progressbar-line"
                              data-percent="55%"
                            >
                              <div className="progressbar-line-item bar-bg-2"></div>
                            </div>
                          </div>
                          <div className="bar-percent">4.7</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="progress-item">
                        <h3 className="progressbar-title">Value for Money</h3>
                        <div className="progressbar-content line-height-20 d-flex align-items-center justify-content-between">
                          <div className="progressbar-box flex-shrink-0">
                            <div
                              className="progressbar-line"
                              data-percent="40%"
                            >
                              <div className="progressbar-line-item bar-bg-3"></div>
                            </div>
                          </div>
                          <div className="bar-percent">2.6</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="progress-item">
                        <h3 className="progressbar-title">Cleanliness</h3>
                        <div className="progressbar-content line-height-20 d-flex align-items-center justify-content-between">
                          <div className="progressbar-box flex-shrink-0">
                            <div
                              className="progressbar-line"
                              data-percent="60%"
                            >
                              <div className="progressbar-line-item bar-bg-4"></div>
                            </div>
                          </div>
                          <div className="bar-percent">3.6</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="progress-item">
                        <h3 className="progressbar-title">Facilities</h3>
                        <div className="progressbar-content line-height-20 d-flex align-items-center justify-content-between">
                          <div className="progressbar-box flex-shrink-0">
                            <div
                              className="progressbar-line"
                              data-percent="50%"
                            >
                              <div className="progressbar-line-item bar-bg-5"></div>
                            </div>
                          </div>
                          <div className="bar-percent">2.6</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section-block"></div> */}
      </div>
      <div className="review-box">
        {props.vehicleReviews.loading ? (
          "Loading"
        ) : props.vehicleReviews.data.reviews.length > 0 ? (
          <div className="single-content-item">
            <h3 className="title font-size-20">
              Showing {props.vehicleReviews.data.reviews.length} reviews
            </h3>
            <div className="comments-list padding-top-50px">
              {props.vehicleReviews.data.reviews.map((reviewInfo, index) => {
                return [
                  <div className="comment">
                    <div className="comment-avatar">
                      <img
                        className="avatar__img"
                        alt=""
                        src={reviewInfo.user.picture}
                      />
                    </div>
                    <div className="comment-body">
                      <div className="meta-data">
                        <h3 className="comment__author">
                          {reviewInfo.user.name}
                        </h3>
                        <div className="meta-data-inner d-flex">
                          <span className="ratings d-flex align-items-center mr-1">
                            <i
                              className={
                                reviewInfo.rating > 1
                                  ? "la la-star filled"
                                  : "la la-star"
                              }
                            ></i>
                            <i
                              className={
                                reviewInfo.rating > 2
                                  ? "la la-star filled"
                                  : "la la-star"
                              }
                            ></i>
                            <i
                              className={
                                reviewInfo.rating > 3
                                  ? "la la-star filled"
                                  : "la la-star"
                              }
                            ></i>
                            <i
                              className={
                                reviewInfo.rating > 4
                                  ? "la la-star filled"
                                  : "la la-star"
                              }
                            ></i>
                            <i
                              className={
                                reviewInfo.rating >= 5
                                  ? "la la-star filled"
                                  : "la la-star"
                              }
                            ></i>
                          </span>
                          <p className="comment__date">{reviewInfo.created}</p>
                        </div>
                      </div>
                      <p className="comment-content">
                      {reviewInfo.reviews}
                      </p>
                    </div>
                  </div>,
                ];
              })}
              {/* <div className="btn-box load-more text-center">
                <button
                  className="theme-btn theme-btn-small theme-btn-transparent"
                  type="button"
                >
                  Load More Review
                </button>
              </div>
             */}
            </div>
          </div>
        ) : (
          "No Reviews yet"
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  vehicleReviews: state.vehicles.vehicleReviews,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(VehicleReviews);
