import React, { useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import Background from "./profile-cover.jpg";
import "./Account.css";

const EditProfile = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <div
        className="edit-profile-bg-sec"
        style={{
          background: `url(${Background})`,
        }}
      ></div>
      <section className="edit-profile-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">
                    <i className="la la-user mr-2 text-gray"></i>My Account
                  </h3>
                </div>
                <div className="form-content">
                  <div className="contact-form-action">
                    <div className="row">
                      <div className="col-lg-12">
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 responsive-column">
                        <div className="input-box">
                          <label className="label-text">{t("username")}</label>
                          <div className="form-group">
                            <span className="la la-user form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Enter Username"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 responsive-column">
                        <div className="input-box">
                          <label className="label-text">Email address</label>
                          <div className="form-group">
                            <span className="la la-envelope form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 responsive-column">
                        <div className="input-box">
                          <label className="label-text">First name</label>
                          <div className="form-group">
                            <span className="la la-user form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 responsive-column">
                        <div className="input-box">
                          <label className="label-text">Last name</label>
                          <div className="form-group">
                            <span className="la la-user form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-lg-12">
                        <h6 className="heading-small text-muted mb-4">
                          Contact Infromation
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">Address</label>
                          <div className="form-group">
                            <span className="la la-pencil form-icon"></span>
                            <textarea
                              className="message-control form-control"
                              name="message"
                              placeholder="Enter the address"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <label className="label-text">City</label>
                          <div className="form-group">
                            <span className="la la-map form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Enter City"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <label className="label-text">Country</label>
                          <div className="form-group">
                            <span className="la la-map form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Enter Country"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <label className="label-text">Postal Code</label>
                          <div className="form-group">
                            <span className="la la-map form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-lg-12">
                        <h6 className="heading-small text-muted mb-4">
                          About Me Information
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">About Me</label>
                          <div className="form-group">
                            <span className="la la-pencil form-icon"></span>
                            <textarea
                              className="message-control form-control"
                              name="message"
                              placeholder="Enter About Yourself"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-box padding-content">
                <div className="form-title-wrap">
                  <h3 className="title">Profile Image</h3>
                </div>
                <div className="form-content">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="user-profile-action d-flex align-items-center pb-4">
                        <div className="user-pro-img">
                          <img src="assets/images/team1.jpg" alt="user" />
                        </div>
                        <div className="upload-btn-box">
                          <p className="pb-2 font-size-15 line-height-24">
                            Max file size is 5MB, Minimum dimension: 150x150 And
                            Suitable files are .jpg &amp; .png
                          </p>
                          <div className="file-upload-wrap file-upload-wrap-2">
                            <input
                              type="file"
                              name="files[]"
                              className="multi file-upload-input with-preview"
                              maxlength="1"
                            />
                            <span className="file-upload-text">
                              <i className="la la-upload mr-2"></i>Upload Image
                            </span>
                            <a href="#" className="theme-btn theme-btn-small">
                              Remove Image
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(EditProfile);
