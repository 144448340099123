import {
  FETCH_BOOKING_HISTORY_START,
  FETCH_BOOKING_HISTORY_SUCCESS,
  FETCH_BOOKING_HISTORY_FAILURE,
  FETCH_BOOKING_UPCOMING_START,
  FETCH_BOOKING_UPCOMING_SUCCESS,
  FETCH_BOOKING_UPCOMING_FAILURE,
  FETCH_BOOKING_CANCELLED_START,
  FETCH_BOOKING_CANCELLED_SUCCESS,
  FETCH_BOOKING_CANCELLED_FAILURE,
  FETCH_BOOKING_VIEW_START,
  FETCH_BOOKING_VIEW_SUCCESS,
  FETCH_BOOKING_VIEW_FAILURE,
  CREATE_BOOKING_START,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  BOOKING_CHECKIN_START,
  BOOKING_CHECKIN_SUCCESS,
  BOOKING_CHECKIN_FAILURE,
  BOOKING_CHECKOUT_START,
  BOOKING_CHECKOUT_SUCCESS,
  BOOKING_CHECKOUT_FAILURE,
  BOOKING_CANCEL_START,
  BOOKING_CANCEL_SUCCESS,
  BOOKING_CANCEL_FAILURE,
  BOOKING_RATE_START,
  BOOKING_RATE_SUCCESS,
  BOOKING_RATE_FAILURE,
} from "./ActionConstant";

// Fetch bookings History actions.

export function fetchBookingHistoryStart(data) {
  return {
    type: FETCH_BOOKING_HISTORY_START,
    data,
  };
}

export function fetchBookingHistorySuccess(data) {
  return {
    type: FETCH_BOOKING_HISTORY_SUCCESS,
    data,
  };
}

export function fetchBookingHistoryFailure(error) {
  return {
    type: FETCH_BOOKING_HISTORY_FAILURE,
    error,
  };
}

// Fetch bookings upcoming actions.
export function fetchBookingUpcomingStart(data) {
  return {
    type: FETCH_BOOKING_UPCOMING_START,
    data,
  };
}

export function fetchBookingUpcomingSuccess(data) {
  return {
    type: FETCH_BOOKING_UPCOMING_SUCCESS,
    data,
  };
}

export function fetchBookingUpcomingFailure(error) {
  return {
    type: FETCH_BOOKING_UPCOMING_FAILURE,
    error,
  };
}

// Fetch bookings cancelled actions.
export function fetchBookingCancelledStart(data) {
  return {
    type: FETCH_BOOKING_CANCELLED_START,
    data,
  };
}

export function fetchBookingCancelledSuccess(data) {
  return {
    type: FETCH_BOOKING_CANCELLED_SUCCESS,
    data,
  };
}

export function fetchBookingCancelledFailure(error) {
  return {
    type: FETCH_BOOKING_CANCELLED_FAILURE,
    error,
  };
}

// Fetch bookings cancelled actions.
export function fetchBookingViewStart(data) {
  return {
    type: FETCH_BOOKING_VIEW_START,
    data,
  };
}

export function fetchBookingViewSuccess(data) {
  return {
    type: FETCH_BOOKING_VIEW_SUCCESS,
    data,
  };
}

export function fetchBookingViewFailure(error) {
  return {
    type: FETCH_BOOKING_VIEW_FAILURE,
    error,
  };
}

// Booking create
export function createBookingStart(data) {
  return {
    type: CREATE_BOOKING_START,
    data,
  };
}

export function createBookingSuccess(data) {
  return {
    type: CREATE_BOOKING_SUCCESS,
    data,
  };
}

export function createBookingFailure(error) {
  return {
    type: CREATE_BOOKING_FAILURE,
    error,
  };
}
