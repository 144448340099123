import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { fetchSingleVehicleStart } from "../../../store/actions/VehicleAction";
import VehicleReviews from "./VehicleReviews";
import moment from "moment";
import DropDownTimePicker from "../../Helper/DropDownTimePicker";
import DatePicker from "react-datepicker";
import "date-fns";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";
import "./VehicleView.css";
import { Link } from "react-router-dom";
import { createBookingStart } from "../../../store/actions/BookingAction";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/NotificationMessage";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  TelegramIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

const VehicleView = (props) => {
  const [priceType, setPriceType] = useState("per_day");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [inputData, setInputData] = useState({
    start_date: new Date(),
    start_time: moment().add(1, "hours")
      .format("HH:00:00"),
    price_type: priceType
  });
  useEffect(() => {
    props.dispatch(
      fetchSingleVehicleStart({
        pro_vehicle_unique_id: props.match.params.pro_vehicle_unique_id,
      })
    );

    if (localStorage.getItem("search_input") != undefined) {
      let localSearchInput = JSON.parse(localStorage.getItem("search_input"));

      if (localSearchInput) {
        if (localSearchInput.start_date) {
          setStartDate(new Date(localSearchInput.start_date));
        }
        if (
          localSearchInput.latitude &&
          localSearchInput.longitude &&
          localSearchInput.full_address
        ) {
          setInputData({
            ...inputData,
            latitude: localSearchInput.latitude,
            longitude: localSearchInput.longitude,
            full_address: localSearchInput.full_address,
          });
        }
      }
    }
  }, []);
  let autocomplete;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!localStorage.getItem("userLoginStatus")) {
      const notificationMessage = getErrorNotificationMessage("Login to continue!!");
      props.dispatch(createNotification(notificationMessage));
    } else {
      if (inputData.length > 0) {
        setInputData(localStorage.getItem("search_input"));
      }
      // props.history.push("/checkout"); // Not used
      props.dispatch(
        createBookingStart({
          pro_vehicle_id: props.vehicleData.data.pro_vehicle_id,
          start_date: moment(inputData.start_date).format(
            "YYYY-MM-DD"
          ),
          payment_mode: "CARD",
          total_days: inputData.total_days ? inputData.total_days : 1,
          start_time: inputData.start_time,
          price_type: priceType
        })
      );
    }

  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "price_type") {
      setPriceType(value);
    }

    setInputData((inputData) => ({ ...inputData, [name]: value }));
  };
  const changeStartDate = (date) => {
    setStartDate(date);
    setInputData({
      ...inputData,
      start_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
    setEndDate(addDays(new Date(), 1));
  };
  const changeEndDate = (date) => {
    setEndDate(date);
    setInputData({
      ...inputData,
      end_date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let full_address = "";
      place.address_components.map(
        (address) =>
        (full_address =
          full_address == ""
            ? address.long_name
            : full_address + "," + address.long_name)
      );
      setInputData({
        ...inputData,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        full_address: full_address,
      });
    });
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const vehicleData = props.vehicleData;
  return (
    <>
      {props.vehicleData.loading ? (
        "Loading..."
      ) : (
        <>
          <section
            className="breadcrumb-area py-0"
            style={{
              backgroundImage: "url('" + props.vehicleData.data.picture + "')",
            }}
          >
            <div className="breadcrumb-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="breadcrumb-btn">
                      <div className="btn-box"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="car-detail-area padding-bottom-90px">
            <div
              className="single-content-navbar-wrap menu section-bg"
              id="single-content-navbar"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-content-nav" id="single-content-nav">
                      <ul>
                        <li>
                          <a
                            data-scroll="description"
                            href="#description"
                            className="scroll-link scroll-link active"
                          >
                            {t("car_details")}
                          </a>
                        </li>
                        <li>
                          <a
                            data-scroll="amenities_section"
                            href="#amenities_section"
                            className="scroll-link"
                          >
                            {t("amenities")}
                          </a>
                        </li>
                        <li>
                          <a
                            data-scroll="reviews_section"
                            href="#reviews_section"
                            className="scroll-link"
                          >
                            {t("reviews")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-content-box">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="single-content-wrap padding-top-60px">
                      <div id="description" className="page-scroll">
                        <div className="single-content-item pb-4">
                          <h3 className="title font-size-26">
                            {vehicleData.data.name}
                          </h3>
                          <div className="d-flex align-items-center pt-2">
                            <p className="mr-2">
                              {vehicleData.data.service_location.name}
                            </p>
                          </div>
                          <p>
                            <span className="badge badge-warning text-white font-size-16 mr-2">
                              {vehicleData.data.overall_rating}
                            </span>
                            <span>
                              ({vehicleData.data.total_reviews} {t("reviews")})
                            </span>
                          </p>
                        </div>
                        <div className="section-block"></div>
                        <div className="single-content-item py-4">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="single-feature-titles mb-3">
                                <h3 className="title font-size-16">
                                  {t("pickup_location")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.location}
                                </span>
                              </div>

                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("no_of_seats")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.no_of_seats} {t("seats")}
                                </span>
                              </div>
                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("model")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.vehicle_model}
                                </span>
                              </div>
                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("manufacture_year")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.manufacture_year}
                                </span>
                              </div>
                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("mileage")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.mileage}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="single-feature-titles mb-3">
                                <h3 className="title font-size-16">
                                  {t("car_type")}
                                </h3>
                                <span className="font-size-13 text-uppercase">
                                  {vehicleData.data.category.name}
                                </span>
                              </div>
                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("no_of_airbags")}
                                </h3>
                                <span className="font-size-13 text-capitalize">
                                  {vehicleData.data.no_of_airbags}{" "}
                                </span>
                              </div>

                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("fuel_tank_capacity")}
                                </h3>
                                <span className="font-size-13 text-capitalize">
                                  {vehicleData.data.fuel_tank_capacity}{" "}
                                  {vehicleData.data.fuel_unit}
                                </span>
                              </div>

                              <div className="section-block"></div>
                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("engine_type")}
                                </h3>
                                <span className="font-size-13 text-capitalize">
                                  {vehicleData.data.engine_type} /{" "}
                                  {vehicleData.data.no_of_gears} {t("gears")}
                                </span>
                              </div>

                              <div className="section-block"></div>

                              <div className="single-feature-titles my-3">
                                <h3 className="title font-size-15 font-weight-medium">
                                  {t("is_fuel_included")}
                                </h3>
                                <span className="font-size-13">
                                  {vehicleData.data.is_fuel_included == 1
                                    ? t("yes")
                                    : t("no")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="section-block"></div>
                        <div className="single-content-item padding-top-40px padding-bottom-40px">
                          <h3 className="title font-size-20">
                            {t("more_information")}
                          </h3>
                          <p
                            className="py-3"
                            dangerouslySetInnerHTML={{
                              __html: vehicleData.data.description,
                            }}
                          ></p>
                        </div>
                        <div className="section-block"></div>
                      </div>
                      <div id="amenities_section" className="page-scroll">
                        <div className="single-content-item padding-top-20px padding-bottom-40px">
                          <h3 className="title font-size-20 padding-bottom-20px">
                            {t("amenities")}
                          </h3>

                          {vehicleData.data.pro_vehicle_amenities.length > 0 ? (
                            <div className="row">
                              {vehicleData.data.pro_vehicle_amenities.map(
                                (amenityInfo, index) => {
                                  return [
                                    <div className="col-lg-6 responsive-column">
                                      <div className="single-tour-feature d-flex align-items-center mb-3">
                                        <div className="single-feature-icon icon-element ml-0 flex-shrink-0 mr-3">
                                          {/* <i className="la la-car"></i> */}
                                          <img
                                            className="amenity-img"
                                            alt={
                                              amenityInfo.pro_vehicle_amenity_id
                                            }
                                            src={amenityInfo.amenity.picture}
                                          ></img>
                                        </div>
                                        <div className="single-feature-titles">
                                          <h3 className="title font-size-15 font-weight-medium">
                                            {amenityInfo.amenity_name}
                                          </h3>
                                          <span className="font-size-13">
                                            {amenityInfo.amenity.description}
                                          </span>
                                        </div>
                                      </div>
                                    </div>,
                                  ];
                                }
                              )}
                            </div>
                          ) : (
                            t("no_amenities_found")
                          )}
                        </div>
                        <div className="section-block"></div>
                      </div>
                      <VehicleReviews
                        pro_vehicle_unique_id={
                          vehicleData.data.pro_vehicle_unique_id
                        }
                      ></VehicleReviews>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sidebar single-content-sidebar mb-0">
                      <div className="sidebar-widget single-content-widget">
                        <div className="sidebar-widget-item">
                          <div className="sidebar-book-title-wrap mb-3">
                            <h3>{t("summary")}</h3>
                            <p className="mb-1">
                              <span className="text-form">From </span>
                              <span className="text-value ml-2 mr-1">
                                {vehicleData.data.price_per_day_formatted}
                                <small className="font-size-15 font-weight-bold ml-1 color-text-3">
                                  /{t("day")}
                                </small>
                              </span>{" "}
                            </p>
                            <p>
                              <span className="text-form">From</span>
                              <span className="text-value ml-2 mr-1">
                                {vehicleData.data.price_per_month_formatted}
                                <small className="font-size-15 font-weight-bold ml-1 color-text-3">
                                  /{t("month")}
                                </small>
                              </span>{" "}
                            </p>
                            <p className="padding-top-sm">
                              <span className="text-form">
                                {vehicleData.data.no_of_seats} {t("seats")}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="sidebar-widget-item">
                          <div className="contact-form-action">
                            <form action="#">
                              {vehicleData.data.is_doorstep_delivery == 1 ? (
                                <div className="input-box">
                                  <label className="label-text">
                                    Pick-up From
                                  </label>
                                  <div className="form-group">
                                    <span className="la la-map-marker form-icon"></span>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder={t(
                                        "pickup_location_placeholder"
                                      )}
                                      name="address"
                                      Autocomplete="off"
                                      defaultValue={
                                        inputData.full_address
                                          ? inputData.full_address
                                          : ""
                                      }
                                      onFocus={renderAutoComplete}
                                      ref={(ref) => (autocomplete = ref)}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <div className="input-box">
                                <label className="label-text">
                                  Choose Price Type
                                </label>
                                <div className="form-group">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="price_type"
                                      id="per_day"
                                      value="per_day"
                                      defaultChecked={
                                        priceType == "per_day" ? true : false
                                      }
                                      onChange={(event) => handleChange(event)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="per_day"
                                    >
                                      {t("per_day")}
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="price_type"
                                      id="per_month"
                                      value="per_month"
                                      defaultChecked={
                                        priceType == "per_month" ? true : false
                                      }
                                      onChange={(event) => handleChange(event)}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="per_month"
                                    >
                                      {t("per_month")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {priceType == "per_day" ? (
                                <div class="mb-3">
                                  <label for="total_days" class="form-label">
                                    {t("total_days_placeholder")}
                                  </label>
                                  <input
                                    type="number"
                                    min="1"
                                    max="29"
                                    class="form-control"
                                    id="total_days"
                                    name="total_days"
                                    placeholder=""
                                    onChange={(event) => handleChange(event)}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="input-box">
                                <label className="label-text">
                                  {t("pickup_date")}
                                </label>
                                <div className="form-group">
                                  <span className="la la-calendar form-icon"></span>

                                  <div className="date-range form-control">
                                    <DatePicker
                                      selected={startDate}
                                      name="start_date"
                                      className="b-0"
                                      minDate={new Date()}
                                      excludeDates={[
                                        new Date(),
                                        subDays(new Date(), 1),
                                      ]}
                                      onChange={(date) => changeStartDate(date)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="input-box">
                                <label className="label-text">Time</label>
                                <div className="form-group height-form">
                                  <DropDownTimePicker
                                    name="start_time"
                                    value={moment()
                                      .add(1, "hours")
                                      .format("HH:00:00")}
                                    currentTime={moment().format("HH:00:00")}
                                    handleChange={handleChange}
                                    className="padding-left-zero"
                                  ></DropDownTimePicker>
                                </div>
                              </div>
                              <div className="input-box hidden">
                                <label className="label-text">
                                  Drop-off Date
                                </label>
                                <div className="form-group">
                                  <span className="la la-calendar form-icon"></span>
                                  <div className="date-range form-control">
                                    <DatePicker
                                      selected={endDate}
                                      name="end_date"
                                      className="b-0"
                                      onChange={(date) => changeEndDate(date)}
                                      minDate={startDate}
                                      excludeDates={[
                                        new Date(),
                                        subDays(new Date(), 1),
                                      ]}
                                      currentTime={moment().format("HH:00:00")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="main-search-input-item hidden">
                          <div className="contact-form-action">
                            <form action="#">
                              <div className="input-box">
                                <label className="label-text">Time</label>
                                <div className="form-group">
                                  <div className="select-contain w-auto">
                                    <DropDownTimePicker
                                      name="end_time"
                                      value={moment().format("00:00:00")}
                                      currentTime={moment().format("00:00:00")}
                                      handleChange={handleChange}
                                      className="padding-left-zero"
                                    ></DropDownTimePicker>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="btn-box pt-2">
                          {/* <Link
                            to={{
                              pathname: "/checkout",
                              vehicleData: props.vehicleData,
                              inputData: inputData,
                            }}
                            className="theme-btn text-center w-100 mb-2"
                          > */}

                          <button onClick={handleSubmit} className="theme-btn text-center w-100 mb-2">
                            <i className="la la-shopping-cart mr-2 font-size-18"></i>
                            {t("book_now")}
                          </button>
                          {/* <a
                            href="#"
                            className="theme-btn text-center w-100 theme-btn-transparent"
                          >
                            <i className="la la-heart-o mr-2"></i>
                            {t("add_to_wishlist")}
                          </a> */}
                          <div className="d-flex align-items-center justify-content-between pt-2">
                            <Link
                              to="#"
                              onClick={handleShareClick}
                              className="btn theme-btn-hover-gray font-size-15"
                            >
                              <i className="la la-share mr-1"></i>
                              {t("share")}
                            </Link>
                            <div className="share-popover-sec">
                              <Popover
                                id={popoverId}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                              >
                                <Typography>
                                  <div className="social-share-sec m-3">
                                    <div className="text-center social-link">
                                      <div className="Demo__some-network">
                                        <EmailShareButton
                                          url={vehicleData.data.share_link}
                                          subject={configuration.get("configData.site_name")}
                                          body={vehicleData.data.share_message}
                                          className="Demo__some-network__share-button"
                                        >
                                          <EmailIcon size={32} round />
                                        </EmailShareButton>
                                      </div>
                                      {/* <h6 className="social-desc">{t("email")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <WhatsappShareButton
                                        url={vehicleData.data.share_link}
                                        title={vehicleData.data.share_message}
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                      >
                                        <WhatsappIcon size={32} round />
                                      </WhatsappShareButton>
                                      {/* <h6 className="social-desc">{t("whatsapp")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <FacebookShareButton
                                        url={vehicleData.data.share_link}
                                        quote={vehicleData.data.share_message}
                                        className="Demo__some-network__share-button"
                                      >
                                        <FacebookIcon size={32} round />
                                      </FacebookShareButton>
                                      {/* <h6 className="social-desc">{t("facebook")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <TwitterShareButton
                                        url={vehicleData.data.share_link}
                                        title={vehicleData.data.share_message}
                                        className="Demo__some-network__share-button"
                                      >
                                        <TwitterIcon size={32} round />
                                      </TwitterShareButton>
                                      {/* <h6 className="social-desc">{t("twitter")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <RedditShareButton
                                        url={vehicleData.data.share_link}
                                        title={vehicleData.data.share_message}
                                        windowWidth={660}
                                        windowHeight={460}
                                        className="Demo__some-network__share-button"
                                      >
                                        <RedditIcon size={32} round />
                                      </RedditShareButton>
                                      {/* <h6 className="social-desc">{t("reddit")}</h6> */}
                                    </div>
                                    <div className="text-center social-link">
                                      <CopyToClipboard
                                        onCopy={onCopy}
                                        text={vehicleData.data.share_link}
                                        windowWidth={660}
                                        windowHeight={460}
                                        className="Demo__some-network__share-button"
                                      >
                                        <button className="react-share__ShareButton Demo__some-network__share-button">
                                          <i className="la la-copy"></i>
                                        </button>
                                      </CopyToClipboard>
                                    </div>
                                  </div>
                                </Typography>
                              </Popover>
                            </div>

                            <p>
                              <i className="la la-eye mr-1 font-size-15 color-text-2"></i>
                              3456
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  vehicleData: state.vehicles.vehicleData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connect(mapStateToPros, mapDispatchToProps)(VehicleView));
