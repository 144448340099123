import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import moment from "moment";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { createBookingStart } from "../../store/actions/BookingAction";

const BookingCreate = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.location.vehicleData === undefined) window.location.replace("/");
    else if (
      (props.location.vehicleData.data.is_doorstep_delivery == 1 ?
      props.location.inputData.full_address !== undefined : true) &&
      props.location.inputData.start_date !== undefined &&
      props.location.inputData.start_time !== undefined &&
      props.location.inputData.total_days !== undefined
    ) {
      setLoading(false);
    } else {
      setLoading(true);
      const notificationMessage = getErrorNotificationMessage(
        "Please fill the details.."
      );
      props.dispatch(createNotification(notificationMessage));
      props.history.goBack();
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(
      createBookingStart({
        pro_vehicle_id: props.location.vehicleData.data.pro_vehicle_id,
        start_date: moment(props.location.inputData.start_date).format(
          "YYYY-MM-DD"
        ),
        payment_mode: "CARD",
        total_days: props.location.inputData.total_days,
        start_time: props.location.inputData.start_time,
      })
    );
  };

  return loading ? (
    "Loading..."
  ) : (
    <>
      <section className="booking-area section--padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {!localStorage.getItem("userId") &&
              !localStorage.getItem("accessToken") ? (
                <div className="form-box">
                  <div className="form-title-wrap">
                    <h3 className="title">Your Personal Information</h3>
                  </div>
                  <div className="form-content ">
                    <div className="contact-form-action">
                      <form method="post">
                        <div className="row">
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">First Name</label>
                              <div className="form-group">
                                <span className="la la-user form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="text"
                                  placeholder="First name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">Last Name</label>
                              <div className="form-group">
                                <span className="la la-user form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="text"
                                  placeholder="Last name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">Your Email</label>
                              <div className="form-group">
                                <span className="la la-envelope-o form-icon"></span>
                                <input
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  placeholder="Email address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">Phone Number</label>
                              <div className="form-group">
                                <span className="la la-phone form-icon"></span>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="text"
                                  placeholder="Phone Number"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">Country</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                  <select className="select-contain-select">
                                    <option value="select-country">
                                      Select country
                                    </option>
                                    <option value="Afghanistan">
                                      Afghanistan
                                    </option>
                                    <option value="Åland Islands">
                                      Åland Islands
                                    </option>
                                    <option value="Albania">Albania</option>
                                    <option value="Algeria">Algeria</option>
                                    <option value="American Samoa">
                                      American Samoa
                                    </option>
                                    <option value="Andorra">Andorra</option>
                                    <option value="Angola">Angola</option>
                                    <option value="Anguilla">Anguilla</option>
                                    <option value="Antarctica">
                                      Antarctica
                                    </option>
                                    <option value="Antigua and Barbuda">
                                      Antigua and Barbuda
                                    </option>
                                    <option value="Argentina">Argentina</option>
                                    <option value="Armenia">Armenia</option>
                                    <option value="Aruba">Aruba</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Austria">Austria</option>
                                    <option value="Azerbaijan">
                                      Azerbaijan
                                    </option>
                                    <option value="Bahamas">Bahamas</option>
                                    <option value="Bahrain">Bahrain</option>
                                    <option value="Bangladesh">
                                      Bangladesh
                                    </option>
                                    <option value="Barbados">Barbados</option>
                                    <option value="Belarus">Belarus</option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="Belize">Belize</option>
                                    <option value="Benin">Benin</option>
                                    <option value="Bermuda">Bermuda</option>
                                    <option value="Bhutan">Bhutan</option>
                                    <option value="Bolivia">Bolivia</option>
                                    <option value="Bosnia and Herzegovina">
                                      Bosnia and Herzegovina
                                    </option>
                                    <option value="Botswana">Botswana</option>
                                    <option value="Bouvet Island">
                                      Bouvet Island
                                    </option>
                                    <option value="Brazil">Brazil</option>
                                    <option value="British Indian Ocean Territory">
                                      British Indian Ocean Territory
                                    </option>
                                    <option value="Brunei Darussalam">
                                      Brunei Darussalam
                                    </option>
                                    <option value="Bulgaria">Bulgaria</option>
                                    <option value="Burkina Faso">
                                      Burkina Faso
                                    </option>
                                    <option value="Burundi">Burundi</option>
                                    <option value="Cambodia">Cambodia</option>
                                    <option value="Cameroon">Cameroon</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Cape Verde">
                                      Cape Verde
                                    </option>
                                    <option value="Cayman Islands">
                                      Cayman Islands
                                    </option>
                                    <option value="Central African Republic">
                                      Central African Republic
                                    </option>
                                    <option value="Chad">Chad</option>
                                    <option value="Chile">Chile</option>
                                    <option value="China">China</option>
                                    <option value="Christmas Island">
                                      Christmas Island
                                    </option>
                                    <option value="Cocos (Keeling) Islands">
                                      Cocos (Keeling) Islands
                                    </option>
                                    <option value="Colombia">Colombia</option>
                                    <option value="Comoros">Comoros</option>
                                    <option value="Congo">Congo</option>
                                    <option value="Congo, The Democratic Republic of The">
                                      Congo, The Democratic Republic of The
                                    </option>
                                    <option value="Cook Islands">
                                      Cook Islands
                                    </option>
                                    <option value="Costa Rica">
                                      Costa Rica
                                    </option>
                                    <option value="Cote D'ivoire">
                                      Cote D'ivoire
                                    </option>
                                    <option value="Croatia">Croatia</option>
                                    <option value="Cuba">Cuba</option>
                                    <option value="Cyprus">Cyprus</option>
                                    <option value="Czech Republic">
                                      Czech Republic
                                    </option>
                                    <option value="Denmark">Denmark</option>
                                    <option value="Djibouti">Djibouti</option>
                                    <option value="Dominica">Dominica</option>
                                    <option value="Dominican Republic">
                                      Dominican Republic
                                    </option>
                                    <option value="Ecuador">Ecuador</option>
                                    <option value="Egypt">Egypt</option>
                                    <option value="El Salvador">
                                      El Salvador
                                    </option>
                                    <option value="Equatorial Guinea">
                                      Equatorial Guinea
                                    </option>
                                    <option value="Eritrea">Eritrea</option>
                                    <option value="Estonia">Estonia</option>
                                    <option value="Ethiopia">Ethiopia</option>
                                    <option value="Falkland Islands (Malvinas)">
                                      Falkland Islands (Malvinas)
                                    </option>
                                    <option value="Faroe Islands">
                                      Faroe Islands
                                    </option>
                                    <option value="Fiji">Fiji</option>
                                    <option value="Finland">Finland</option>
                                    <option value="France">France</option>
                                    <option value="French Guiana">
                                      French Guiana
                                    </option>
                                    <option value="French Polynesia">
                                      French Polynesia
                                    </option>
                                    <option value="French Southern Territories">
                                      French Southern Territories
                                    </option>
                                    <option value="Gabon">Gabon</option>
                                    <option value="Gambia">Gambia</option>
                                    <option value="Georgia">Georgia</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Gibraltar">Gibraltar</option>
                                    <option value="Greece">Greece</option>
                                    <option value="Greenland">Greenland</option>
                                    <option value="Grenada">Grenada</option>
                                    <option value="Guadeloupe">
                                      Guadeloupe
                                    </option>
                                    <option value="Guam">Guam</option>
                                    <option value="Guatemala">Guatemala</option>
                                    <option value="Guernsey">Guernsey</option>
                                    <option value="Guinea">Guinea</option>
                                    <option value="Guinea-bissau">
                                      Guinea-bissau
                                    </option>
                                    <option value="Guyana">Guyana</option>
                                    <option value="Haiti">Haiti</option>
                                    <option value="Heard Island and Mcdonald Islands">
                                      Heard Island and Mcdonald Islands
                                    </option>
                                    <option value="Holy See (Vatican City State)">
                                      Holy See (Vatican City State)
                                    </option>
                                    <option value="Honduras">Honduras</option>
                                    <option value="Hong Kong">Hong Kong</option>
                                    <option value="Hungary">Hungary</option>
                                    <option value="Iceland">Iceland</option>
                                    <option value="India">India</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Iran, Islamic Republic of">
                                      Iran, Islamic Republic of
                                    </option>
                                    <option value="Iraq">Iraq</option>
                                    <option value="Ireland">Ireland</option>
                                    <option value="Isle of Man">
                                      Isle of Man
                                    </option>
                                    <option value="Israel">Israel</option>
                                    <option value="Italy">Italy</option>
                                    <option value="Jamaica">Jamaica</option>
                                    <option value="Japan">Japan</option>
                                    <option value="Jersey">Jersey</option>
                                    <option value="Jordan">Jordan</option>
                                    <option value="Kazakhstan">
                                      Kazakhstan
                                    </option>
                                    <option value="Kenya">Kenya</option>
                                    <option value="Kiribati">Kiribati</option>
                                    <option value="Korea, Democratic People's Republic of">
                                      Korea, Democratic People's Republic of
                                    </option>
                                    <option value="Korea, Republic of">
                                      Korea, Republic of
                                    </option>
                                    <option value="Kuwait">Kuwait</option>
                                    <option value="Kyrgyzstan">
                                      Kyrgyzstan
                                    </option>
                                    <option value="Lao People's Democratic Republic">
                                      Lao People's Democratic Republic
                                    </option>
                                    <option value="Latvia">Latvia</option>
                                    <option value="Lebanon">Lebanon</option>
                                    <option value="Lesotho">Lesotho</option>
                                    <option value="Liberia">Liberia</option>
                                    <option value="Libyan Arab Jamahiriya">
                                      Libyan Arab Jamahiriya
                                    </option>
                                    <option value="Liechtenstein">
                                      Liechtenstein
                                    </option>
                                    <option value="Lithuania">Lithuania</option>
                                    <option value="Luxembourg">
                                      Luxembourg
                                    </option>
                                    <option value="Macao">Macao</option>
                                    <option value="Macedonia, The Former Yugoslav Republic of">
                                      Macedonia, The Former Yugoslav Republic of
                                    </option>
                                    <option value="Madagascar">
                                      Madagascar
                                    </option>
                                    <option value="Malawi">Malawi</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Maldives">Maldives</option>
                                    <option value="Mali">Mali</option>
                                    <option value="Malta">Malta</option>
                                    <option value="Marshall Islands">
                                      Marshall Islands
                                    </option>
                                    <option value="Martinique">
                                      Martinique
                                    </option>
                                    <option value="Mauritania">
                                      Mauritania
                                    </option>
                                    <option value="Mauritius">Mauritius</option>
                                    <option value="Mayotte">Mayotte</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Micronesia, Federated States of">
                                      Micronesia, Federated States of
                                    </option>
                                    <option value="Moldova, Republic of">
                                      Moldova, Republic of
                                    </option>
                                    <option value="Monaco">Monaco</option>
                                    <option value="Mongolia">Mongolia</option>
                                    <option value="Montenegro">
                                      Montenegro
                                    </option>
                                    <option value="Montserrat">
                                      Montserrat
                                    </option>
                                    <option value="Morocco">Morocco</option>
                                    <option value="Mozambique">
                                      Mozambique
                                    </option>
                                    <option value="Myanmar">Myanmar</option>
                                    <option value="Namibia">Namibia</option>
                                    <option value="Nauru">Nauru</option>
                                    <option value="Nepal">Nepal</option>
                                    <option value="Netherlands">
                                      Netherlands
                                    </option>
                                    <option value="Netherlands Antilles">
                                      Netherlands Antilles
                                    </option>
                                    <option value="New Caledonia">
                                      New Caledonia
                                    </option>
                                    <option value="New Zealand">
                                      New Zealand
                                    </option>
                                    <option value="Nicaragua">Nicaragua</option>
                                    <option value="Niger">Niger</option>
                                    <option value="Nigeria">Nigeria</option>
                                    <option value="Niue">Niue</option>
                                    <option value="Norfolk Island">
                                      Norfolk Island
                                    </option>
                                    <option value="Northern Mariana Islands">
                                      Northern Mariana Islands
                                    </option>
                                    <option value="Norway">Norway</option>
                                    <option value="Oman">Oman</option>
                                    <option value="Pakistan">Pakistan</option>
                                    <option value="Palau">Palau</option>
                                    <option value="Palestinian Territory, Occupied">
                                      Palestinian Territory, Occupied
                                    </option>
                                    <option value="Panama">Panama</option>
                                    <option value="Papua New Guinea">
                                      Papua New Guinea
                                    </option>
                                    <option value="Paraguay">Paraguay</option>
                                    <option value="Peru">Peru</option>
                                    <option value="Philippines">
                                      Philippines
                                    </option>
                                    <option value="Pitcairn">Pitcairn</option>
                                    <option value="Poland">Poland</option>
                                    <option value="Portugal">Portugal</option>
                                    <option value="Puerto Rico">
                                      Puerto Rico
                                    </option>
                                    <option value="Qatar">Qatar</option>
                                    <option value="Reunion">Reunion</option>
                                    <option value="Romania">Romania</option>
                                    <option value="Russian Federation">
                                      Russian Federation
                                    </option>
                                    <option value="Rwanda">Rwanda</option>
                                    <option value="Saint Helena">
                                      Saint Helena
                                    </option>
                                    <option value="Saint Kitts and Nevis">
                                      Saint Kitts and Nevis
                                    </option>
                                    <option value="Saint Lucia">
                                      Saint Lucia
                                    </option>
                                    <option value="Saint Pierre and Miquelon">
                                      Saint Pierre and Miquelon
                                    </option>
                                    <option value="Saint Vincent and The Grenadines">
                                      Saint Vincent and The Grenadines
                                    </option>
                                    <option value="Samoa">Samoa</option>
                                    <option value="San Marino">
                                      San Marino
                                    </option>
                                    <option value="Sao Tome and Principe">
                                      Sao Tome and Principe
                                    </option>
                                    <option value="Saudi Arabia">
                                      Saudi Arabia
                                    </option>
                                    <option value="Senegal">Senegal</option>
                                    <option value="Serbia">Serbia</option>
                                    <option value="Seychelles">
                                      Seychelles
                                    </option>
                                    <option value="Sierra Leone">
                                      Sierra Leone
                                    </option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Slovakia">Slovakia</option>
                                    <option value="Slovenia">Slovenia</option>
                                    <option value="Solomon Islands">
                                      Solomon Islands
                                    </option>
                                    <option value="Somalia">Somalia</option>
                                    <option value="South Africa">
                                      South Africa
                                    </option>
                                    <option value="South Georgia and The South Sandwich Islands">
                                      South Georgia and The South Sandwich
                                      Islands
                                    </option>
                                    <option value="Spain">Spain</option>
                                    <option value="Sri Lanka">Sri Lanka</option>
                                    <option value="Sudan">Sudan</option>
                                    <option value="Suriname">Suriname</option>
                                    <option value="Svalbard and Jan Mayen">
                                      Svalbard and Jan Mayen
                                    </option>
                                    <option value="Swaziland">Swaziland</option>
                                    <option value="Sweden">Sweden</option>
                                    <option value="Switzerland">
                                      Switzerland
                                    </option>
                                    <option value="Syrian Arab Republic">
                                      Syrian Arab Republic
                                    </option>
                                    <option value="Taiwan, Province of China">
                                      Taiwan, Province of China
                                    </option>
                                    <option value="Tajikistan">
                                      Tajikistan
                                    </option>
                                    <option value="Tanzania, United Republic of">
                                      Tanzania, United Republic of
                                    </option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Timor-leste">
                                      Timor-leste
                                    </option>
                                    <option value="Togo">Togo</option>
                                    <option value="Tokelau">Tokelau</option>
                                    <option value="Tonga">Tonga</option>
                                    <option value="Trinidad and Tobago">
                                      Trinidad and Tobago
                                    </option>
                                    <option value="Tunisia">Tunisia</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Turkmenistan">
                                      Turkmenistan
                                    </option>
                                    <option value="Turks and Caicos Islands">
                                      Turks and Caicos Islands
                                    </option>
                                    <option value="Tuvalu">Tuvalu</option>
                                    <option value="Uganda">Uganda</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">
                                      United Arab Emirates
                                    </option>
                                    <option value="United Kingdom">
                                      United Kingdom
                                    </option>
                                    <option value="United States">
                                      United States
                                    </option>
                                    <option value="United States Minor Outlying Islands">
                                      United States Minor Outlying Islands
                                    </option>
                                    <option value="Uruguay">Uruguay</option>
                                    <option value="Uzbekistan">
                                      Uzbekistan
                                    </option>
                                    <option value="Vanuatu">Vanuatu</option>
                                    <option value="Venezuela">Venezuela</option>
                                    <option value="Viet Nam">Viet Nam</option>
                                    <option value="Virgin Islands, British">
                                      Virgin Islands, British
                                    </option>
                                    <option value="Virgin Islands, U.S.">
                                      Virgin Islands, U.S.
                                    </option>
                                    <option value="Wallis and Futuna">
                                      Wallis and Futuna
                                    </option>
                                    <option value="Western Sahara">
                                      Western Sahara
                                    </option>
                                    <option value="Yemen">Yemen</option>
                                    <option value="Zambia">Zambia</option>
                                    <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 responsive-column">
                            <div className="input-box">
                              <label className="label-text">Country Code</label>
                              <div className="form-group">
                                <div className="select-contain w-auto">
                                  <select className="select-contain-select">
                                    <option value="country-code">
                                      Select country code
                                    </option>
                                    <option value="1">
                                      United Kingdom (+44)
                                    </option>
                                    <option value="2">
                                      United States (+1)
                                    </option>
                                    <option value="3">Bangladesh (+880)</option>
                                    <option value="4">Brazil (+55)</option>
                                    <option value="5">China (+86)</option>
                                    <option value="6">India (+91)</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input-box">
                              <div className="custom-checkbox mb-0">
                                <input type="checkbox" id="receiveChb" />
                                <label for="receiveChb">
                                  I want to receive Trizen promotional offers in
                                  the future
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="form-box">
                <form onSubmit={handleSubmit}>
                  <div className="form-title-wrap">
                    <h3 className="title">{t("checkout")}</h3>
                  </div>
                  <div className="form-content">
                    <ul className="list-items list-items-3 py-2 checkout-card">
                      <li>
                        <span>{t("price_type")}:</span> {props.location.inputData.price_type == "per_day" ? "Per Day" : "Per Month"}
                      </li>
                      <li>
                        <span>{t("period")}</span>{" "}
                        {props.location.inputData.total_days} {props.location.inputData.price_type == "days" ? "Per Day" : "Months"}
                      </li>

                      <li>
                        <span>{t("per_price")}:</span>{" "}
                        {props.location.inputData.price_type == "per_day" ? 
                          props.location.vehicleData.data
                            .price_per_day_formatted : props.location.vehicleData.data
                            .price_per_month_formatted
                        }
                      </li>
                      <li>
                        <span>{t("doorstep_service")}:</span> {props.location.vehicleData.is_doorstep_delivery == 0 ? "N/A" : "Available"}
                      </li>

                      <li>
                        <span>{t("sub_total")}:</span> 
                        {props.location.inputData.price_type == "per_day" ? 
                        <span>{configuration.get("configData.currency")}{props.location.vehicleData.data
                          .price_per_day * props.location.inputData.total_days} ({props.location.inputData.total_days} * {props.location.vehicleData.data
                            .price_per_day_formatted})</span>
                           : <span>{configuration.get("configData.currency")}{props.location.vehicleData.data
                            .price_per_month * 1} (1 * {props.location.vehicleData.data
                              .price_per_month_formatted})</span>
                        }
                       
                      </li>
                      {/* <li>
                        <span>{t("taxes_fees")}: $ 0.00</span> 
                      </li> */}
                      <li>
                        <span>{t("security_deposit")} (Refundable):</span>{" "}
                        {props.location.vehicleData.data.security_deposit_formatted}
                      </li>
                      <li>
                        <span>{t("payment_mode")}:</span> Card
                      </li>
                      <li className="f-12">
                        <span>{t("total_payable_amount")}:</span> $100.00
                      </li>
                    </ul>
                  </div>

                  <div className="col-lg-12">
                    <div className="btn-box mb-3">
                      <button className="theme-btn" type="submit">
                        Confirm Booking
                      </button>
                    </div>
                  </div>

                  <div class="alert alert-warning mb-0" role="alert">
                    <h4 class="alert-heading">
                      <i className="la la-bell"></i> Note:
                    </h4>
                    <p className="mt-1">
                      Please Note, You will not be permitted to change the
                      address after payment.
                    </p>
                    <p className="mt-1">
                      Security Deposit will be Refundable once the booking
                      completes.
                    </p>
                    <hr></hr>
                  </div>
                </form>
              </div>

              <div className="form-box hidden">
                <div className="form-title-wrap">
                  <h3 className="title">Your Card Information</h3>
                </div>
                <div className="form-content">
                  <div className="section-tab check-mark-tab text-center pb-4">
                    <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="credit-card-tab"
                          data-toggle="tab"
                          href="#credit-card"
                          role="tab"
                          aria-controls="credit-card"
                          aria-selected="false"
                        >
                          <i className="la la-check icon-element"></i>
                          <img src="assets/images/payment-img.png" alt="" />
                          <span className="d-block pt-2">
                            Payment with credit card
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="paypal-tab"
                          data-toggle="tab"
                          href="#paypal"
                          role="tab"
                          aria-controls="paypal"
                          aria-selected="true"
                        >
                          <i className="la la-check icon-element"></i>
                          <img src="assets/images/paypal.png" alt="" />
                          <span className="d-block pt-2">
                            Payment with PayPal
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="payoneer-tab"
                          data-toggle="tab"
                          href="#payoneer"
                          role="tab"
                          aria-controls="payoneer"
                          aria-selected="true"
                        >
                          <i className="la la-check icon-element"></i>
                          <img src="assets/images/payoneer.png" alt="" />
                          <span className="d-block pt-2">
                            Payment with payoneer
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="credit-card"
                      role="tabpanel"
                      aria-labelledby="credit-card-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                  Card Holder Name
                                </label>
                                <div className="form-group">
                                  <span className="la la-credit-card form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder="Card holder name"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                  Card Number
                                </label>
                                <div className="form-group">
                                  <span className="la la-credit-card form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder="Card number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="row">
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                      Expiry Month
                                    </label>
                                    <div className="form-group">
                                      <span className="la la-credit-card form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="MM"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 responsive-column">
                                  <div className="input-box">
                                    <label className="label-text">
                                      Expiry Year
                                    </label>
                                    <div className="form-group">
                                      <span className="la la-credit-card form-icon"></span>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="text"
                                        placeholder="YY"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-box">
                                <label className="label-text">CVV</label>
                                <div className="form-group">
                                  <span className="la la-pencil form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder="CVV"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-box">
                                <div className="form-group">
                                  <div className="custom-checkbox">
                                    <input type="checkbox" id="agreechb" />
                                    <label for="agreechb">
                                      By continuing, you agree to the{" "}
                                      <a href="#">Terms and Conditions</a>.
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="btn-box">
                                <button className="theme-btn" type="submit">
                                  Confirm Booking
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="paypal"
                      role="tabpanel"
                      aria-labelledby="paypal-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                  Email Address
                                </label>
                                <div className="form-group">
                                  <span className="la la-envelope form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Enter email address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">Password</label>
                                <div className="form-group">
                                  <span className="la la-lock form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder="Enter password"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="btn-box">
                                <button className="theme-btn" type="submit">
                                  Login Account
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="payoneer"
                      role="tabpanel"
                      aria-labelledby="payoneer-tab"
                    >
                      <div className="contact-form-action">
                        <form method="post">
                          <div className="row">
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">
                                  Email Address
                                </label>
                                <div className="form-group">
                                  <span className="la la-envelope form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Enter email address"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 responsive-column">
                              <div className="input-box">
                                <label className="label-text">Password</label>
                                <div className="form-group">
                                  <span className="la la-lock form-icon"></span>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="text"
                                    placeholder="Enter password"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="btn-box">
                                <button className="theme-btn" type="submit">
                                  Login Account
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-box booking-detail-form">
                <div className="form-title-wrap">
                  <h3 className="title">Booking Details</h3>
                </div>
                <div className="form-content">
                  <div className="card-item shadow-none radius-none mb-0">
                    <div className="card-img pb-4">
                      <a href={`/vehicle-details`} className="d-block">
                        <img
                          src={props.location.vehicleData.data.picture}
                          alt="car-img"
                        />
                      </a>
                    </div>
                    <div className="card-body p-0">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h3 className="card-title">
                            {props.location.vehicleData.data.name}
                          </h3>
                          <p className="card-meta">
                            {props.location.vehicleData.data.location}
                          </p>
                        </div>
                        <div>
                          <a href={`/vehicle-details`} className="btn ml-1">
                            <i
                              className="la la-edit"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                            ></i>
                          </a>
                        </div>
                      </div>
                      <div className="card-rating">
                        <span className="badge text-white">
                          {props.location.vehicleData.data.overall_rating}/5
                        </span>
                        <span className="review__text">{t("average")}</span>
                        <span className="rating__text">
                          ({props.location.vehicleData.data.total_reviews}{" "}
                          {t("reviews")})
                        </span>
                      </div>
                      <div className="section-block"></div>
                      <ul className="list-items list-items-2 list--items-2 py-2">
                        <li className="font-size-15">
                          <span className="w-auto d-block mb-n1">
                            <i className="la la-calendar mr-1 font-size-17"></i>
                            Date
                          </span>
                          {/* {props.location.inputData.start_date} */}
                        </li>
                        <li className="font-size-15">
                          <span className="w-auto d-block mb-n1">
                            <i className="la la-clock-o mr-1 font-size-17"></i>
                            Time
                          </span>
                          {props.location.inputData.start_time}
                        </li>
                        <li className="font-size-15">
                          <span className="w-auto d-block mb-n1">
                            <i className="la la-map-marker mr-1 font-size-17"></i>
                            Location
                          </span>
                          {props.location.inputData.full_address}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-block"></div>

      <section className="info-area info-bg padding-top-90px padding-bottom-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 responsive-column">
              <a href="#" className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb text-color-2">
                  <i className="la la-phone"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">Need Help? Contact us</h4>
                  <p className="info__desc">
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 responsive-column">
              <a href="#" className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb-2 text-color-3">
                  <i className="la la-money"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">Payments</h4>
                  <p className="info__desc">
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 responsive-column">
              <a href="#" className="icon-box icon-layout-2 d-flex">
                <div className="info-icon flex-shrink-0 bg-rgb-3 text-color-4">
                  <i className="la la-times"></i>
                </div>
                <div className="info-content">
                  <h4 className="info__title">Cancel Policy</h4>
                  <p className="info__desc">
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(BookingCreate);
