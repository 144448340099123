import React, { useState } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import configuration from "react-global-configuration";
import SocialButton from "../Helper/SocialButton";
import { forgotPasswordStart, userRegisterStart } from "../../store/actions/UserAction";
import LoginModal from "./LoginModal";

const ForgotPasswordModal = (props) => {
    const [showLogin, setShowLogin] = useState(false);
    const handleLoginClose = () => setShowLogin(false);

    const [loginInputData, setLoginInputData] = useState({});
    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(forgotPasswordStart(loginInputData));
    };


    return (
        <>
            <div className="modal-popup">
                <Modal
                    show={props.showForgotPassword}
                    onHide={props.handleClose}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title title" id="exampleModalLongTitle2">
                                {t("forgot_password")}
                            </h5>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={props.handleClose}
                        >
                            <span aria-hidden="true" className="la la-close"></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="contact-form-action">
                            <form method="post">
                                <div className="input-box">
                                    <label className="label-text">{t("email")}</label>
                                    <div className="form-group">
                                        <span className="la la-user form-icon"></span>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={t("email_placeholder")}
                                            required
                                            value={loginInputData.email}
                                            name="email"
                                            onChange={(event) =>
                                                setLoginInputData({
                                                    ...loginInputData,
                                                    email: event.currentTarget.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="btn-box pt-3 pb-4">
                                    <button
                                        type="submit"
                                        className="theme-btn w-100"
                                        onClick={handleSubmit}
                                        disabled={props.forgotPassword.buttonDisable}
                                    >
                                        {props.forgotPassword.loadingButtonContent !== null
                                            ? props.forgotPassword.loadingButtonContent
                                            : t("send_reset_link")}{" "}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </Modal>

            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    // login: state.users.loginInputData,
    // signup: state.users.registerInputData,
    forgotPassword: state.users.forgotPasswordInputData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ForgotPasswordModal);
