import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import { Modal } from "react-bootstrap";
import configuration from "react-global-configuration";
import SocialButton from "../Helper/SocialButton";
import { userRegisterStart } from "../../store/actions/UserAction";
import { gapi } from "gapi-script";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const RegisterModal = (props) => {
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });

  }, []);

  const [registerInputData, setRegisterInputData] = useState({});
  const handleRegister = (event) => {
    event.preventDefault();
    props.dispatch(userRegisterStart(registerInputData));
  };

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };
  return (
    <>
      <div className="modal-popup">
        <Modal
          show={props.showRegister}
          onHide={props.handleRegisterClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <div>
              <h5 className="modal-title title" id="exampleModalLongTitle">
                {t("signup")}
              </h5>
              <p className="font-size-14">{t("signup_note")}</p>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.handleRegisterClose}
            >
              <span aria-hidden="true" className="la la-close"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="contact-form-action">
              <form method="post">
                <div className="input-box">
                  <label className="label-text">{t("first_name")}</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control"
                      type="text"
                      name="first_name"
                      placeholder={t("first_name_placeholder")}
                      required
                      value={registerInputData.first_name}
                      onChange={(event) =>
                        setRegisterInputData({
                          ...registerInputData,
                          first_name: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("last_name")}</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder={t("last_name_placeholder")}
                      required
                      value={registerInputData.last_name}
                      onChange={(event) =>
                        setRegisterInputData({
                          ...registerInputData,
                          last_name: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("email_address")}</label>
                  <div className="form-group">
                    <span className="la la-envelope form-icon"></span>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder={t("email_placeholder")}
                      required
                      value={registerInputData.email}
                      onChange={(event) =>
                        setRegisterInputData({
                          ...registerInputData,
                          email: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("mobile")}</label>
                  <div className="form-group">
                    <span className="la la-envelope form-icon"></span>
                    <input
                      className="form-control"
                      type="number"
                      name="mobile"
                      placeholder={t("mobile_placeholder")}
                      required
                      value={registerInputData.mobile}
                      onChange={(event) =>
                        setRegisterInputData({
                          ...registerInputData,
                          mobile: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">{t("password")}</label>
                  <div className="form-group">
                    <span className="la la-lock form-icon"></span>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder={t("password_placeholder")}
                      required
                      value={registerInputData.password}
                      onChange={(event) =>
                        setRegisterInputData({
                          ...registerInputData,
                          password: event.currentTarget.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-box">
                      <input type="checkbox" id="agreechb" />
                       
                      <label className="form-label">
                        {t("i_agree_to")}
                        <Link
                          target="_blank"
                          to="/page/terms"
                          className="terms-link"
                        >
                          {" "}
                          {t("terms_conditions")}{" "}
                        </Link>
                        of {" "}{configuration.get("configData.site_name")}
                      </label>
                      
                    </div>
                <div className="btn-box pt-3 pb-4">
                  <button
                    type="submit"
                    className="theme-btn w-100"
                    onClick={handleRegister}
                  >
                    {t("signup_submit")}
                  </button>
                </div>
                <div className="action-box text-center">
                  <p className="font-size-14">{t("social_register_heading")}</p>
                  <ul className="social-profile py-3">
                    <li>
                      {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                        <SocialButton
                          provider="google"
                          key={"google"}
                          appId={configuration.get(
                            "configData.GOOGLE_CLIENT_ID"
                          )}
                          onLoginSuccess={handleGoogleLogin}
                          onLoginFailure={handleSocialLoginFailure}
                          className="btn bg-5 text-white"
                          id="google-connect"
                        >
                          <span>
                            <i className="lab la-google"></i>
                          </span>
                        </SocialButton>
                      ) : (
                        ""
                      )}

                    </li>
                    <li>
                      {configuration.get("configData.FB_CLIENT_ID") ? (
                        <SocialButton
                          provider="facebook"
                          appId={configuration.get("configData.FB_CLIENT_ID")}
                          onLoginSuccess={handleFacebookLogin}
                          onLoginFailure={handleSocialLoginFailure}
                          className="btn bg-6 text-white"
                          id="facebook-connect"
                        >
                          <span>
                            <i className="lab la-facebook-f"></i>
                          </span>
                        </SocialButton>
                      ) : (
                        ""
                      )}</li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  signup: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RegisterModal);
