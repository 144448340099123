import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  ADD_WISHLIST_START,
  GET_WISHLIST_START,
  CLEAR_WISHLIST_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  addWishlistSuccess,
  addWishlistFailure,
  getWishlistSuccess,
  getWishlistFailure,
  clearWishlistFailure,
  clearWishlistSuccess,
} from "../actions/WishlistAction";

function* addWishlistAPI() {
  try {
    const inputData = yield select(
      (state) => state.wishlists.addWishlist.inputData
    );
    const response = yield api.postMethod("wishlist_operations", inputData);

    if (response.data.success) {
      yield put(addWishlistSuccess(response.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/");
    } else {
      yield put(addWishlistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(addWishlistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getWishlistAPI() {
  try {
    const response = yield api.postMethod("wishlists");

    if (response.data.success) {
      yield put(getWishlistSuccess(response.data.data));
    } else {
      yield put(getWishlistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(getWishlistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* clearWishlistAPI() {
  try {
    const inputData = yield select(
      (state) => state.wishlists.clearWishlistInput.inputData
    );
    const response = yield api.postMethod("wishlist_clear", inputData);

    if (response.data.success) {
      yield put(clearWishlistSuccess(response.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      window.location.assign("/wishlists");
    } else {
      yield put(clearWishlistFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(clearWishlistFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ADD_WISHLIST_START, addWishlistAPI)]);
  yield all([yield takeLatest(GET_WISHLIST_START, getWishlistAPI)]);
  yield all([yield takeLatest(CLEAR_WISHLIST_START, clearWishlistAPI)]);
}
