import React, { useState } from "react";
import { connect } from "react-redux";
import { t } from "react-multi-lang";
import Background from "./profile-cover.jpg";
import { changePasswordStart } from "../../store/actions/UserAction";
import "./Account.css";

const ChangePassword = (props) => {
  const [inputData, setInputData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    props.dispatch(changePasswordStart(inputData));
  };
  return (
    <>
      <div
        className="change-password-bg-sec"
        style={{
          background: `url(${Background})`,
        }}
      ></div>
      <section className="change-password-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="form-box">
                <div className="form-title-wrap">
                  <h3 className="title">{t("change_password")}</h3>
                </div>
                <div className="form-content">
                  <div className="contact-form-action">
                    <form autoComplete="new-password" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 responsive-column">
                          <div className="input-box">
                            <label className="label-text">
                              {t("current_password")}
                            </label>
                            <div className="form-group">
                              <span className="la la-lock form-icon"></span>
                              <input
                                className="form-control"
                                type="password"
                                autoComplete="off"
                                placeholder={t("current_password")}
                                name="old_password"
                                value={inputData.old_password}
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    old_password: event.currentTarget.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 responsive-column">
                          <div className="input-box">
                            <label className="label-text">
                              {t("new_password")}
                            </label>
                            <div className="form-group">
                              <span className="la la-lock form-icon"></span>
                              <input
                                className="form-control"
                                type="password"
                                autoComplete="off"
                                placeholder={t("new_password")}
                                name="password"
                                value={inputData.password}
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    password: event.currentTarget.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 responsive-column">
                          <div className="input-box">
                            <label className="label-text">
                              {t("repeat_password")}
                            </label>
                            <div className="form-group">
                              <span className="la la-lock form-icon"></span>
                              <input
                                className="form-control"
                                type="password"
                                placeholder={t("repeat_password")}
                                name="password_confirmation"
                                autoComplete="off"
                                value={inputData.password_confirmation}
                                onChange={(event) =>
                                  setInputData({
                                    ...inputData,
                                    password_confirmation:
                                      event.currentTarget.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="btn-box">
                            <button
                              className="theme-btn"
                              type="submit"
                              disabled={props.changePassword.buttonDisable}
                              onClick={handleSubmit}
                            >
                              {props.changePassword.loadingButtonContent != null
                                ? props.changePassword.loadingButtonContent
                                : t("change_password")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.users.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ChangePassword);
