import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import CardLoader from "../../Handlers/Loaders/CardLoader";
import AddCardModal from "../../Helper/AddCardModal";
import "./Card.css";
import {
  deleteCardStart,
  fetchCardsStart,
  selectDefaultCardStart,
} from "../../../store/actions/CardAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentAddCardModal from "../../Helper/PaymentAddCardModal";
import { Link } from "react-router-dom";

const CardsIndex = (props) => {
  useEffect(() => {
    props.dispatch(fetchCardsStart());
  }, []);
  const [addCard, setAddCard] = useState(false);
  const [paymentAddCard, setPaymentAddCard] = useState(false);

  const stripePromise = loadStripe(
    configuration.get("configData.stripe_publishable_key")
  );
  const closeAddCardModal = () => {
    setAddCard(false);
  };
  const closePaymentAddCardModal = () => {
    setPaymentAddCard(false);
  };

  const changeDefaultCard = (event, card) => {
    event.preventDefault();
    props.dispatch(selectDefaultCardStart({ user_card_id: card.user_card_id }));
  };

  const deleteCard = (event, card) => {
    event.preventDefault();
    props.dispatch(deleteCardStart({ user_card_id: card.user_card_id }));
  };

  const { cards } = props;

  return (
    <>
      <section className="funfact-area section--padding card-list-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <h2 className="sec__title">{t("card_management")}</h2>
              </div>
            </div>
          </div>
          <div className="counter-box counter-box-2 margin-top-60px mb-0">
            <div className="row">
              {cards.loading ? (
                <CardLoader />
              ) : cards.data.cards.length > 0 ? (
                cards.data.cards.map((cardInfo, index) => (
                  <div className="col-lg-4 responsive-column">
                    <div className="counter-item counter-item-layout-2 d-flex">
                      <div className="counter-icon flex-shrink-0">
                        <i className="la la-credit-card"></i>
                      </div>
                      <div className="counter-content">
                        <div className="msg-content w-100">
                          <h3 className="title pb-1">
                            XXXX XXXX XXXX {cardInfo.last_four}
                          </h3>
                          <p className="msg-text">{cardInfo.card_type}</p>
                        </div>
                        <div className="card-action-btn-sec">
                          {cardInfo.is_default == 1 ? (
                            <ul className="list-unstyled action-btn">
                              <li>
                                <a href="#">{t("default_card")}</a>
                              </li>
                            </ul>
                          ) : (
                            <ul className="list-unstyled">
                              {cardInfo.is_default == 0 ? (
                                <li>
                                  <a
                                    href="#"
                                    onClick={(event) =>
                                      changeDefaultCard(event, cardInfo)
                                    }
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        "/assets/images/tick-card.svg"
                                      }
                                      alt="default card"
                                      className="action-icon"
                                    />
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  href="#"
                                  onClick={(event) =>
                                    deleteCard(event, cardInfo)
                                  }
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      "/assets/images/delete-card.svg"
                                    }
                                    alt="delete card"
                                    className="action-icon"
                                  />
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : null}
            </div>

            <div className="row">
              <div className="col-lg-4 responsive-column">
                <a href="#" onClick={() => setPaymentAddCard(true)}>
                  <div className="counter-item counter-item-layout-2 add-card-box d-flex align-items-center justify-content-center mb-0">
                    <img
                      src={
                        window.location.origin + "/assets/images/plus-card.svg"
                      }
                      alt="add-card"
                      className="plus-icon"
                    />
                    <p className="card-title font-size-15 font-weight-medium">
                      {t("add_card")}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Elements stripe={stripePromise}>
        <PaymentAddCardModal
          paymentAddCard={paymentAddCard}
          closePaymentAddCardModal={closePaymentAddCardModal}
        />
      </Elements>

      <div className="modal-popup">
        <div
          className="modal fade"
          id="add-card-moadal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title title" id="exampleModalLongTitle">
                    Add Card
                  </h5>
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="la la-close"></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="contact-form-action">
                  <form method="post">
                    <div className="input-box">
                      <label className="label-text">Card Number</label>
                      <div className="form-group">
                        <span className="la la-credit-card form-icon"></span>
                        <input
                          className="form-control"
                          type="text"
                          name="text"
                          placeholder="Type your card number"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input-box">
                          <label className="label-text">MM</label>
                          <div className="form-group">
                            <span className="la la-calendar-alt form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="MM"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-box">
                          <label className="label-text">YY</label>
                          <div className="form-group">
                            <span className="la la-calendar-alt form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="YY"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-box">
                          <label className="label-text">CVC</label>
                          <div className="form-group">
                            <span className="la la-pencil form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="text"
                              placeholder="CVC"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="add-card-action-btn-sec">
                          <button
                            className="theme-btn theme-btn-small submit-btn mr-3"
                            type="submit"
                          >
                            <i className="la la-check-circle mr-1"></i>Add
                          </button>
                          <button
                            className="theme-btn theme-btn-small border-0 text-white bg-7"
                            type="submit"
                          >
                            <i className="la la-times mr-1"></i>Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  cards: state.cards.cardList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(CardsIndex);
