import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import ErrorSaga from "./ErrorSaga";
import BillingAccountSaga from "./BillingAccountSaga";
import WishlistSaga from "./WishlistSaga";
import NotificationSaga from "./NotificationSaga";
import PageSaga from "./PageSaga";
import WalletSaga from "./WalletSaga";
import TransactionSaga from "./TransactionSaga";
import KycDocumentSaga from "./KycDocumentSaga";
import BookingSaga from "./BookingSaga";
import VehicleSaga from "./VehicleSaga";
import CardsSaga from "./CardsSaga";

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(WishlistSaga)]);
  yield all([fork(BillingAccountSaga)]);
  yield all([fork(ErrorSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(WalletSaga)]);
  yield all([fork(TransactionSaga)]);
  yield all([fork(KycDocumentSaga)]);
  yield all([fork(BookingSaga)]);
  yield all([fork(VehicleSaga)]);
  yield all([fork(CardsSaga)]);

  //   yield all([fork(ChangePasswordSaga)]);
  //   yield all([fork(TransactionSaga)]);
  //   yield all([fork(WithDrawSaga)]);
  //   yield all([fork(HomeSaga)]);
  //   yield all([fork(ChatSaga)]);
  //   yield all([fork(NotificationSaga)]);
}
