import React from "react"
import ContentLoader from "react-content-loader"

const StaticLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={700}
    viewBox="0 0 1200 700"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="246" y="644" rx="0" ry="0" width="80" height="11" /> 
    <rect x="195" y="196" rx="0" ry="0" width="234" height="21" /> 
    <rect x="234" y="238" rx="0" ry="0" width="153" height="20" /> 
    <rect x="4" y="300" rx="20" ry="20" width="593" height="314" /> 
    <rect x="12" y="20" rx="10" ry="10" width="581" height="148" />
  </ContentLoader>
)

export default StaticLoader;