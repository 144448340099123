import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import "./Booking.css";
import { fetchBookingHistoryStart } from "../../store/actions/BookingAction";
import NoDataFound from "../NoDataFound/NoDataFound";
import useInfiniteScroll from "../Helper/useInfiniteScroll";

const BookingHistory = (props) => {
  useEffect(() => {
    props.dispatch(
      fetchBookingHistoryStart({
        skip: props.bookingList.skip ? props.bookingList.skip : 0,
      })
    );
  }, []);

  const [isFetching, setIsFetching] = useInfiniteScroll(
    fetchBookingHistoryData
  );

  const [noMoreData, setNoMoreData] = useState(false);

  function fetchBookingHistoryData() {
    setTimeout(() => {
      if (props.bookingList.data.bookings.length !== 0) {
        props.dispatch(
          fetchBookingHistoryStart({ skip: props.bookingList.skip })
        );
        setIsFetching(false);
      } else {
        setNoMoreData(true);
      }
    }, 3000);
  }
  return (
    <>
      <section className="funfact-area section--padding booking-history-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title">{t("bookings")}</h2>
              </div>
            </div>
          </div>
          {props.bookingList.loading ? (
            ""
          ) : props.bookingList.data.bookings.length > 0 ? (
            <div className="booking-history-card margin-top-60px mb-0">
              {props.bookingList.data.bookings.map((bookingDetails, index) => {
                return [
                  <>
                    <div className="row booking-history-content-sec ">
                      <div className="col-lg-5 resp-mrg-btm-md">
                        <div className="booking-history-user-details">
                          <div className="vehicle-img-sec">
                            <img
                              src={bookingDetails.pro_vehicle.picture}
                              alt={bookingDetails.booking_unique_id}
                            />
                          </div>
                          <div className="booking-details">
                            <h3 className="title">
                              {bookingDetails.provider.name
                                ? bookingDetails.provider.name
                                : t("n_a")}
                            </h3>
                            <p className="card-meta">
                              <Link
                                to={
                                  `/booking/` + bookingDetails.booking_unique_id
                                }
                              >
                                {" "}
                                #{bookingDetails.booking_unique_id}
                              </Link>
                            </p>
                            <p className="card-meta">
                              <Link to={`vehicle-details`}>
                                {bookingDetails.pro_vehicle.name}
                              </Link>
                              (
                              {bookingDetails.pro_vehicle.no_of_seats
                                ? bookingDetails.pro_vehicle.no_of_seats
                                : 2}{" "}
                              {t("seats")})
                            </p>
                            <p className="card-meta">
                              {bookingDetails.pro_vehicle.vehicle_number}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="car-booking-details">
                          <p className="card-desc">
                            {t("total")}
                            <strong>
                              {" "}
                              : {bookingDetails.total_formatted}
                            </strong>{" "}
                            | {t("security_deposit")}
                            <strong>
                              {" "}
                              : {bookingDetails.security_deposit_formatted}
                            </strong>
                          </p>
                          <p className="card-desc">
                            {t("start_date")}
                            <strong>
                              {" "}
                              : {bookingDetails.start_date_formatted}
                            </strong>
                          </p>
                          <p className="card-desc">
                            {t("end_date")}
                            <strong>
                              {" "}
                              :{" "}
                              {bookingDetails.end_date_formatted
                                ? bookingDetails.end_date_formatted
                                : t("n_a")}
                            </strong>
                          </p>
                          <p className="card-desc">
                            {t("status")}
                            <strong>
                              {" "}
                              :{" "}
                              <span className="text-warning">
                                {bookingDetails.status_text_formatted}
                              </span>
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="add-card-action-btn-sec">
                          {bookingDetails.buttons.cancel_btn_status == 1 ? (
                            <button
                              className="theme-btn theme-btn-small border-0 text-white bg-7 mr-3"
                              type="submit"
                            >
                              <i className="la la-times mr-1"></i>
                              {t("cancel")}
                            </button>
                          ) : null}
                          <Link
                            className="theme-btn theme-btn-small submit-btn"
                            to={`/booking/` + bookingDetails.booking_unique_id}
                          >
                            <i className="la la-check-circle mr-1"></i>View
                          </Link>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </>,
                ];
              })}
            </div>
          ) : props.bookingList.skip == 0 ? (
            <NoDataFound></NoDataFound>
          ) : null}

          {/* <div className="btn-box text-right bottom-load-more">
            <button type="button" className="theme-btn">
              <i className="la la-refresh mr-1"></i>Load More
            </button>{" "}
          </div> */}
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  bookingList: state.bookings.bookingList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(BookingHistory);
