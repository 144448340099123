import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_SINGLE_VEHICLE_START,
  FETCH_VEHICLE_REVIEWS_START,
  SEARCH_VEHICLES_START,
  TOP_SERVICE_LOCATIONS_START,
  TRENDING_VEHICLES_START,
  FETCH_FAQS_START,
} from "../actions/ActionConstant";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/Helper/NotificationMessage";
import {
  searchVehiclesSuccess,
  searchVehiclesFailure,
  trendingVehiclesSuccess,
  trendingVehiclesFailure,
  topServiceLocationsSuccess,
  topServiceLocationsFailure,
  fetchSingleVehicleFailure,
  fetchSingleVehicleSuccess,
  fetchVehicleReviewsSuccess,
  fetchVehicleReviewsFailure,
  fetchFaqsSuccess,
  fetchFaqsFailure,
} from "../actions/VehicleAction";

function* searchVehiclesAPI() {
  try {
    const inputData = yield select(
      (state) => state.vehicles.vehicles.inputData
    );

    const response = yield api.postMethod("vehicles_search", inputData);
    if (response.data.success) {
      yield put(searchVehiclesSuccess(response.data.data));
    } else {
      yield put(searchVehiclesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(searchVehiclesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* categoryListAPI() {
  try {
    const response = yield api.postMethod("categories_list");
    if (response.data.success) {
      yield put(searchVehiclesSuccess(response.data.data));
    } else {
      yield put(searchVehiclesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(searchVehiclesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* trendingVehiclesAPI() {
  try {
    const response = yield api.postMethod("trending_vehicles");
    if (response.data.success) {
      yield put(trendingVehiclesSuccess(response.data.data));
    } else {
      yield put(trendingVehiclesFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(trendingVehiclesFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* topServiceLocationsAPI() {
  try {
    const response = yield api.postMethod("top_service_locations");
    if (response.data.success) {
      yield put(topServiceLocationsSuccess(response.data.data));
    } else {
      yield put(topServiceLocationsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(topServiceLocationsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSingleVehicleAPI() {
  try {
    const inputData = yield select(
      (state) => state.vehicles.vehicleData.inputData
    );
    const response = yield api.postMethod("pro_vehicles_view", inputData);
    if (response.data.success) {
      yield put(fetchSingleVehicleSuccess(response.data.data));
    } else {
      yield put(fetchSingleVehicleFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSingleVehicleFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}
function* fetchVehicleReviewsAPI() {
  try {
    const inputData = yield select(
      (state) => state.vehicles.vehicleReviews.inputData
    );
    const response = yield api.postMethod("pro_vehicles_reviews", inputData);
    if (response.data.success) {
      yield put(fetchVehicleReviewsSuccess(response.data.data));
    } else {
      yield put(fetchVehicleReviewsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchVehicleReviewsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchFaqsAPI(action) {
  try {
    const response = yield api.postMethod("faqs_list");
    if (response.data.success) {
      yield put(fetchFaqsSuccess(response.data.data));
    } else {
      yield put(fetchFaqsFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchFaqsFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(SEARCH_VEHICLES_START, searchVehiclesAPI)]);
  yield all([yield takeLatest(TRENDING_VEHICLES_START, trendingVehiclesAPI)]);
  yield all([
    yield takeLatest(TOP_SERVICE_LOCATIONS_START, topServiceLocationsAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_VEHICLE_START, fetchSingleVehicleAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_VEHICLE_REVIEWS_START, fetchVehicleReviewsAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_FAQS_START, fetchFaqsAPI),
  ]);

}
