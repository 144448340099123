import React from "react"
import ContentLoader from "react-content-loader"

const CardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={300}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="7" y="38" rx="5" ry="5" width="182" height="82" /> 
    <rect x="206" y="37" rx="5" ry="5" width="182" height="82" /> 
    <rect x="403" y="36" rx="5" ry="5" width="182" height="82" /> 
    <rect x="9" y="147" rx="5" ry="5" width="182" height="112" />
  </ContentLoader>
)

export default CardLoader;