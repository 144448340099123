import {
  FETCH_BOOKING_HISTORY_START,
  FETCH_BOOKING_HISTORY_SUCCESS,
  FETCH_BOOKING_HISTORY_FAILURE,
  FETCH_BOOKING_UPCOMING_START,
  FETCH_BOOKING_UPCOMING_SUCCESS,
  FETCH_BOOKING_UPCOMING_FAILURE,
  FETCH_BOOKING_CANCELLED_START,
  FETCH_BOOKING_CANCELLED_SUCCESS,
  FETCH_BOOKING_CANCELLED_FAILURE,
  FETCH_BOOKING_VIEW_START,
  FETCH_BOOKING_VIEW_SUCCESS,
  FETCH_BOOKING_VIEW_FAILURE,
  CREATE_BOOKING_START,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  bookingList: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  upcomingBookingList: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  cancelledBookingList: {
    data: {},
    loading: true,
    error: false,
    skip: 0,
    length: 0,
  },
  booking: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  bookingInputData: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  buttonDisable: false,
  loadingButtonContent: null,
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOOKING_HISTORY_START:
      return {
        ...state,
        bookingList: {
          data: {},
          loading: true,
          error: false,
          skip: state.bookingList.skip,
          length: state.bookingList.length,
        },
      };
    case FETCH_BOOKING_HISTORY_SUCCESS:
      return {
        ...state,
        bookingList: {
          data: action.data,
          loading: false,
          error: false,
          skip: action.data.bookings.length + state.bookingList.skip,
          length: action.data.bookings.length,
        },
      };
    case FETCH_BOOKING_HISTORY_FAILURE:
      return {
        ...state,
        bookingList: {
          data: {},
          loading: true,
          error: action.error,
          skip: state.bookingList.skip,
          length: state.bookingList.length,
        },
      };

    case FETCH_BOOKING_UPCOMING_START:
      return {
        ...state,
        upcomingBookingList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_BOOKING_UPCOMING_SUCCESS:
      return {
        ...state,
        upcomingBookingList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_BOOKING_UPCOMING_FAILURE:
      return {
        ...state,
        upcomingBookingList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_BOOKING_CANCELLED_START:
      return {
        ...state,
        cancelledBookingList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_BOOKING_CANCELLED_SUCCESS:
      return {
        ...state,
        cancelledBookingList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_BOOKING_CANCELLED_FAILURE:
      return {
        ...state,
        cancelledBookingList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case FETCH_BOOKING_VIEW_START:
      return {
        ...state,
        booking: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
        },
      };
    case FETCH_BOOKING_VIEW_SUCCESS:
      return {
        ...state,
        booking: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_BOOKING_VIEW_FAILURE:
      return {
        ...state,
        booking: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };

    case CREATE_BOOKING_START:
      return {
        ...state,
        bookingInputData: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        bookingInputData: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case CREATE_BOOKING_FAILURE:
      return {
        ...state,
        bookingInputData: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };

    default:
      return state;
  }
};

export default BookingReducer;
