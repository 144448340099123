import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import configuration from "react-global-configuration";
import { t } from "react-multi-lang";
import { topServiceLocationsStart } from "../../store/actions/VehicleAction";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

import "./Home.css";

const TopServiceLocations = (props) => {
  useEffect(() => {
    props.dispatch(topServiceLocationsStart());
  }, []);

  return (
    <>
      {props.serviceLocations.loading ? (
        ""
      ) : props.serviceLocations.data.total > 0 ? (
        <section className="destination-area padding-top-50px padding-bottom-70px">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="section-heading">
                  <h2 className="sec__title">{t("top_service_locations")}</h2>
                  <p className="sec__desc pt-3">
                    {t("top_service_locations_note", {
                      sitename: configuration.get("configData.site_name"),
                    })}
                    {t("home.Title", { param: "react" })}
                  </p>
                </div>
              </div>

              {/* <div className="col-lg-4">
              <div className="btn-box btn--box text-right">
                <a href="tour-grid.html" className="theme-btn">
                  Discover More <i className="la la-arrow-right ml-1"></i>
                </a>
              </div>
            </div> */}
            </div>
            <div className="row padding-top-50px">
              {props.serviceLocations.data.service_locations.map(
                (serviceLocation, index) => {
                  return [
                    <div className="col-lg-4 responsive-column">
                      <div className="card-item destination-card destination--card">
                        <div className="card-img">
                          <img
                            src={serviceLocation.picture}
                            alt={serviceLocation.name}
                            className="home-to-location-img"
                          />
                        </div>
                        <div className="card-body d-flex align-items-center justify-content-between">
                          <div>
                            <h3 className="card-title mb-0">
                              <a href={`/vehicles`}>{serviceLocation.name}</a>
                            </h3>
                          </div>
                          <div>
                            <Link
                              className="theme-btn"
                              to={{
                                pathname: `/vehicles`,
                                state: {
                                  inputData: {
                                    latitude: serviceLocation.latitude,
                                    longitude: serviceLocation.longitude,
                                    full_address: serviceLocation.address,
                                  },
                                },
                              }}
                            >
                              Search Now
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>,
                  ];
                }
              )}
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  serviceLocations: state.vehicles.serviceLocations,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(TopServiceLocations);
