import React, { useState, useEffect } from "react";

const DropDownTimePicker = (props) => {
  const [inputData, setInputData] = useState({});

  return (
    <>
      <select
        className="select-contain-select form-control"
        name={props.name}
        onChange={(event) => props.handleChange(event)}
      >
        <option
          value="00:00:00"
          selected={props.value == "00:00:00" ? true : false}
        >
          12:00AM
        </option>
        <option
          value="01:00:00"
          selected={props.value == "01:00:00" ? true : false}
        >
          1:00AM
        </option>
        <option
          value="02:00:00"
          selected={props.value == "02:00:00" ? true : false}
        >
          2:00AM
        </option>
        <option
          value="03:00:00"
          selected={props.value == "03:00:00" ? true : false}
        >
          3:00AM
        </option>
        <option
          value="04:00:00"
          selected={props.value == "04:00:00" ? true : false}
        >
          4:00AM
        </option>
        <option
          value="05:00:00"
          selected={props.value == "05:00:00" ? true : false}
        >
          5:00AM
        </option>
        <option
          value="06:00:00"
          selected={props.value == "06:00:00" ? true : false}
        >
          6:00AM
        </option>
        <option
          value="07:00:00"
          selected={props.value == "07:00:00" ? true : false}
        >
          7:00AM
        </option>
        <option
          value="08:00:00"
          selected={props.value == "08:00:00" ? true : false}
        >
          8:00AM
        </option>
        <option
          value="09:00:00"
          selected={props.value == "09:00:00" ? true : false}
        >
          9:00AM
        </option>
        <option
          value="10:00:00"
          selected={props.value == "10:00:00" ? true : false}
        >
          10:00AM
        </option>
        <option
          value="11:00:00"
          selected={props.value == "11:00:00" ? true : false}
        >
          11:00AM
        </option>
        <option
          value="12:00:00"
          selected={props.value == "12:00:00" ? true : false}
        >
          12:00PM
        </option>
        <option
          value="13:00:00"
          selected={props.value == "13:00:00" ? true : false}
        >
          1:00PM
        </option>
        <option
          value="14:00:00"
          selected={props.value == "14:00:00" ? true : false}
        >
          2:00PM
        </option>
        <option
          value="15:00:00"
          selected={props.value == "15:00:00" ? true : false}
        >
          3:00PM
        </option>
        <option
          value="16:00:00"
          selected={props.value == "16:00:00" ? true : false}
        >
          4:00PM
        </option>
        <option
          value="17:00:00"
          selected={props.value == "17:00:00" ? true : false}
        >
          5:00PM
        </option>
        <option
          value="18:00:00"
          selected={props.value == "18:00:00" ? true : false}
        >
          6:00PM
        </option>
        <option
          value="19:00:00"
          selected={props.value == "19:00:00" ? true : false}
        >
          7:00PM
        </option>
        <option
          value="20:00:00"
          selected={props.value == "20:00:00" ? true : false}
        >
          8:00PM
        </option>
        <option
          value="21:00:00"
          selected={props.value == "21:00:00" ? true : false}
        >
          9:00PM
        </option>
        <option
          value="22:00:00"
          selected={props.value == "22:00:00" ? true : false}
        >
          10:00PM
        </option>
        <option
          value="23:00:00"
          selected={props.value == "23:00:00" ? true : false}
        >
          11:00PM
        </option>
      </select>
    </>
  );
};

export default DropDownTimePicker;
